import React from 'react';

interface ThreadDateSeparatorProps {
    date: string;
    children: React.ReactNode;
}

const ThreadDateSeparator: React.FC<ThreadDateSeparatorProps> = ({ date, children }) => {
    return (
        <div className="my-4">
            <div className="text-center mx-auto my-2 text-sm text-theme-light">
                <span>{date}</span>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

export default ThreadDateSeparator;