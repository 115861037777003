import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CustomerList from '../features/customer/CustomerList';
import CustomerForm from '../features/customer/CustomerForm';
import CustomerDetail from '../features/customer/CustomerDetail';
import SidePanel from "../components/SidePanel";

const CustomersPage: React.FC<{ setActionButton: (button: React.ReactNode) => void }> = ({ setActionButton }) => {
    const navigate = useNavigate();

    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any | null>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Ajout de l'état de chargement

    const customerFormRef = useRef<any>(null); // Utilisation de useRef pour CustomerForm

    // Fonction pour ouvrir le SidePanel pour créer un client
    const handleCreateCustomer = () => {
        setIsSidePanelOpen(true);
        setSelectedCustomer(null);
        setIsEditMode(false);
    };

    // Définir le bouton "Nouveau Client" dans NavbarTop
    useEffect(() => {
        setActionButton(
            <button
                className="px-4 py-2 bg-indigo-600 text-white rounded"
                onClick={handleCreateCustomer} // Ouvre le SidePanel pour créer un client
            >
                Nouveau Client
            </button>
        );

        // Nettoyer le bouton lors du démontage
        return () => setActionButton(null);
    }, [setActionButton]);

    const handleEditCustomer = (customer: any) => {
        setIsSidePanelOpen(true);
        setSelectedCustomer(customer);
        setIsEditMode(true);
    };

    const handleViewDetails = (uuid: string) => {
        navigate(`/customers/${uuid}`);
    };

    const closeSidePanel = () => {
        setIsSidePanelOpen(false);
    };

    // Fonction de validation
    const handleValidate = () => {
        if (customerFormRef.current) {
            customerFormRef.current.submitForm(); // Validation du formulaire client
        }
    };

    return (
        <div className="p-4 h-full">
            <Routes>
                <Route
                    path="/"
                    element={<CustomerList onEditCustomer={handleEditCustomer} onViewDetails={handleViewDetails} />}
                />
                <Route
                    path=":uuid"
                    element={<CustomerDetail onEditCustomer={handleEditCustomer} />}
                />
            </Routes>

            <SidePanel
                isOpen={isSidePanelOpen}
                onClose={closeSidePanel}
                onValidate={handleValidate} // Validation via le bouton de confirmation
            >
                <div data-page="1" data-validation-text="Créer un client">
                    <CustomerForm
                        ref={customerFormRef} // Référence pour le formulaire
                        onSuccess={closeSidePanel} // Ferme le SidePanel après succès
                        customerData={selectedCustomer} // Données du client si édition
                        isEditMode={isEditMode}
                        isLoading={isLoading} // Passer l'état de chargement au CustomerForm
                    />
                </div>
            </SidePanel>
        </div>
    );
};

export default CustomersPage;