import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { fetchThreadsWithLastMessage } from './messagingSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from '../../components/Skeleton';

const ThreadList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { threadId } = useParams<{ threadId: string }>();
    const { threads, loading, unreadMessages } = useSelector((state: RootState) => state.messaging);

    useEffect(() => {
        dispatch(fetchThreadsWithLastMessage()); // Appel de la fonction pour récupérer les threads avec les derniers messages
    }, [dispatch]);

    if (loading) {
        return (
            <div className="grid grid-cols-3 gap-4 p-4">
                {[...Array(6)].map((_, i) => (
                    <div key={i} className="bg-white p-4 rounded shadow">
                        <Skeleton height="20px" width="70%" />
                        <Skeleton height="20px" width="50%" />
                        <Skeleton height="30px" width="100%" />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="flex-1 p-4">
            <h2 className="text-xl font-bold mb-4">Messagerie</h2>
            {threads.length === 0 ? (
                <p>Aucun thread trouvé.</p>
            ) : (
                <div className="grid grid-cols-3 gap-4">
                    {threads.map((thread: any) => {
                        const isActiveThread = threadId === thread.uuid;
                        const hasUnreadMessages = unreadMessages[thread.uuid] > 0 && !isActiveThread;

                        return (
                            <div key={thread.uuid} className="relative bg-white p-4 rounded shadow">
                                <h3 className="text-lg font-bold">{thread.subject}</h3>
                                <p className={hasUnreadMessages ? "font-bold" : ""}>{thread.lastMessage?.body || 'Pas de message'}</p> {/* Correction ici */}
                                <p><em>De: {thread.lastMessage?.sender?.firstName} {thread.lastMessage?.sender?.lastName}</em></p> {/* Auteur du dernier message */}
                                <button
                                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
                                    onClick={() => navigate(`/messaging/${thread.uuid}`)}
                                >
                                    Voir les messages
                                </button>
                                {hasUnreadMessages && (
                                    <span className="absolute top-2 right-2 bg-red-500 text-white text-xs rounded-full w-3 h-3"></span>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ThreadList;