import axiosInstance from './apiService';
import qs from 'qs';

// Interface pour un message
interface MessageData {
    body: string;
}

// Fonction pour récupérer tous les threads de messagerie
export const getThreads = () => {
    return axiosInstance.get('/v1/threads')
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des threads:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour récupérer les messages d'un thread spécifique avec pagination
export const getMessages = (threadUuid: string, limit: number = 20, skip: number = 0, order: 'ASC' | 'DESC' = 'DESC') => {
    return axiosInstance.get(`/v1/threads/${threadUuid}/messages`, {
        params: { limit, skip, order },
    })
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des messages:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour envoyer un message dans un thread spécifique
export const sendMessage = (threadUuid: string, messageContent: string, nonce: string) => {
    const data = qs.stringify({
        body: messageContent,
        nonce: nonce,
    });

    return axiosInstance.post(`/v1/threads/${threadUuid}/messages`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de l\'envoi du message:', error.response?.data || error.message);
            throw error;
        });
}

// Fonction pour récupérer un message spécifique dans un thread
export const getMessage = (threadUuid: string, messageUuid: string) => {
    return axiosInstance.get(`/v1/threads/${threadUuid}/messages/${messageUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération du message:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour supprimer un message spécifique dans un thread
export const deleteMessage = (threadUuid: string, messageUuid: string) => {
    return axiosInstance.delete(`/v1/threads/${threadUuid}/messages/${messageUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la suppression du message:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour modifier un message spécifique dans un thread
export const updateMessage = (threadUuid: string, messageUuid: string, messageData: MessageData) => {
    const data = qs.stringify(messageData);  // Conversion des données en x-www-form-urlencoded
    return axiosInstance.patch(`/v1/threads/${threadUuid}/messages/${messageUuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la modification du message:', error.response?.data || error.message);
            throw error;
        });
};

export const getThreadDetail = (threadUuid: string) => {
    return axiosInstance.get(`/v1/threads/${threadUuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération du thread:', error.response?.data || error.message);
            throw error;
        });
};

/**
 * Génère un nonce aléatoire de 20 caractères
 */
// const generateNonce = () => {
//     return (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 20);
// }
