import React from 'react';

interface SkeletonProps {
    width?: string;
    height?: string;
    additionalClasses?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({ width = '100%', height = '20px', additionalClasses = '' }) => {
    return (
        <div
            className={`bg-gray-300 animate-pulse ${additionalClasses}`}
            style={{ width, height, margin: '0 0 10px' }}
        ></div>
    );
};

export default Skeleton;
