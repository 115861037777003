import axiosInstance from './apiService';
import qs from 'qs';

// Interface pour les données d'inscription
interface RegisterData {
    email: string;
    firstName: string;
    lastName: string;
    plainPassword: string;
}

// Interface pour les données de connexion
interface LoginData {
    username: string;
    password: string;
}

// Fonction d'inscription
export const register = (userData: RegisterData) => {
    const data = qs.stringify(userData);
    return axiosInstance.post('/v1/register', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        if (response.data.accessToken) {
            localStorage.setItem('accessToken', response.data.accessToken);
        }
        return response;
    });
};

// Fonction de connexion
export const login = (userData: LoginData) => {
    const data = qs.stringify(userData);
    return axiosInstance.post('/v1/login', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        if (response.data.accessToken) {
            localStorage.setItem('accessToken', response.data.accessToken);
        }
        return response;
    });
};

export const getUserInfo = () => {
    return axiosInstance.get('/v1/users/me');
};

export const updateUserInfo = (userData: any) => {
    const data = qs.stringify(userData);
    return axiosInstance.patch('/v1/users/me', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

// Fonction pour associer un utilisateur à un client via un token d'invitation
export const associateToCustomer = (invitationToken: string) => {
    const data = new URLSearchParams();
    data.append('invitationToken', invitationToken); // Utilisez le bon nom de paramètre ici

    return axiosInstance.post('/v1/users/me/associate-to-customer', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

// Fonction de déconnexion
export const logout = () => {
    localStorage.removeItem('accessToken');
};