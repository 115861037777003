import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { updateUserInfo } from '../../../api/authService';
import { setUser } from '../../auth/authSlice';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';

const UserInformationForm: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);

    const [formData, setFormData] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
    });

    const [loading, setLoading] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>('');

    React.useEffect(() => {
        if (user) {
            setFormData({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            });
            setLoading(false);
        }
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await updateUserInfo(formData);
            dispatch(setUser(response.data));
            setMessage(t('settings.updateSuccess'));
        } catch (error) {
            console.error(t('settings.updateError'), error);
            setMessage(t('settings.updateError'));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('settings.userInformation')}
            </label>
            <div className="space-y-6">
                {loading ? (
                    <>
                        <Skeleton height="40px"/>
                        <Skeleton height="40px"/>
                        <Skeleton height="40px"/>
                    </>
                ) : (
                    <>
                        <div>
                            <input
                                name="email"
                                type="email"
                                required
                                className="block w-full px-3 py-2 border rounded-md"
                                placeholder={t('user.email')}
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <input
                                name="firstName"
                                type="text"
                                required
                                className="block w-full px-3 py-2 border rounded-md"
                                placeholder={t('user.firstName')}
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <input
                                name="lastName"
                                type="text"
                                required
                                className="block w-full px-3 py-2 border rounded-md"
                                placeholder={t('user.lastName')}
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </div>
                    </>
                )}
                <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    {t('settings.update')}
                </button>
                {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            </div>
        </form>
);
};

export default UserInformationForm;