import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, getUserInfo } from '../../api/authService';
import { useDispatch } from 'react-redux';
import { setUser } from './authSlice';
import { setCompany } from './../company/companySlice';

const LoginForm: React.FC = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');

        try {
            // Connexion
            const loginResponse = await login({
                username: formData.email,
                password: formData.password,
            });

            if (loginResponse.status === 200) {
                // Récupérer les informations utilisateur
                const userInfoResponse = await getUserInfo();
                dispatch(setUser(userInfoResponse.data));

                if (userInfoResponse.data.company) {
                    dispatch(setCompany(userInfoResponse.data.company));
                    navigate('/dashboard');
                } else {
                    navigate('/create-company');
                }
            } else {
                setErrorMessage('Email ou mot de passe incorrect');
            }
        } catch (error) {
            setErrorMessage('Email ou mot de passe incorrect');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Connexion</h2>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input
                                name="email"
                                type="email"
                                required
                                placeholder="Adresse email"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                name="password"
                                type="password"
                                required
                                placeholder="Mot de passe"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="text-center mt-4 text-red-500">
                            {errorMessage}
                        </div>
                    )}

                    <div className={"flex items-center justify-between"}>
                        <button type="submit" disabled={isLoading} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                                    ></path>
                                </svg>
                            ) : (
                                "Se connecter"
                            )}
                        </button>
                    </div>

                    <div className="text-center mt-4">
                        <button
                            type="button"
                            className="text-indigo-600 hover:text-indigo-500"
                            onClick={() => navigate('/signup')}
                        >
                            Pas encore de compte ? Inscrivez-vous
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
