import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiGrid, FiLayers, FiUsers, FiMessageSquare, FiFile, FiSettings, FiUser, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setUser } from '../features/auth/authSlice';
import { updateUserInfo } from '../api/authService';
import { RootState } from '../store/store';
import Logo from './Logo';
import logoIcon from './../assets/images/test_icon_bricksly.svg';

type NavbarSideProps = {
    theme: string;
    toggleTheme: (newTheme: string) => void;
};

const NavbarSide: React.FC<NavbarSideProps> = ({ theme, toggleTheme }) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(() => {
        const savedState = localStorage.getItem('navbar-collapsed');
        return savedState === 'true';
    });
    const [isHovered, setIsHovered] = useState(false); // Ajout de l'état pour détecter le hover
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { threadId } = useParams<{ threadId: string }>(); // Récupérer l'ID du thread actuel à partir de l'URL
    const user = useSelector((state: RootState) => state.auth.user);
    const unreadMessages = useSelector((state: RootState) => state.messaging.unreadMessages);
    const hasUnreadMessages = Object.keys(unreadMessages).some(id => id !== threadId && unreadMessages[id] > 0);

    const toggleNavbar = () => {
        const newState: boolean = !collapsed;
        setCollapsed(newState);
        localStorage.setItem('navbar-collapsed', newState.toString());
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    const handleProfileChange = async (profile: 'company' | 'customer') => {
        if (user) {
            try {
                const updatedUser = {
                    ...user,
                    profileSelected: profile,
                };
                const response = await updateUserInfo(updatedUser);
                dispatch(setUser(response.data));
            } catch (error) {
                console.error("Erreur lors de la mise à jour du profil:", error);
            }
        }
    };

    return (
        <div
            className={`relative text-black h-full transition-all duration-300 ${collapsed ? 'w-[5.25rem]' : 'w-64'}`}
            onMouseEnter={() => setIsHovered(true)} // Détecter quand la souris entre
            onMouseLeave={() => setIsHovered(false)} // Détecter quand la souris sort
        >
            <div className="flex items-center mt-8 pl-8">
                {collapsed ? (
                    <img src={logoIcon} alt="Logo Icon" className="h-8 whitespace-nowrap leading-none" />
                ) : (
                    <Logo theme={theme} />
                )}
            </div>

            {isHovered && ( // Afficher le bouton uniquement si la souris est sur la sidebar
                <button
                    onClick={toggleNavbar}
                    className="absolute p-2 focus:outline-none bg-white border border-secondaryBgColor rounded-full"
                    style={{
                        right: '-17px',
                        top: '2rem',
                    }}
                >
                    {collapsed ? <FiChevronRight /> : <FiChevronLeft />}
                </button>
            )}

            <ul className="mt-16 pl-4 pr-4">
                <li className="flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer" onClick={() => navigate('/dashboard')}>
                    <span className="transition-none"><FiGrid className="text-[1.3rem] text-secondaryColor leading-none" /></span>
                    {!collapsed && <span className="ml-4 min-w-[50px] whitespace-nowrap leading-none text-theme">{t('navbar.dashboard')}</span>}
                </li>
                <li className="flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer" onClick={() => navigate('/projects')}>
                    <span className="transition-none"><FiLayers className="text-[1.3rem] text-secondaryColor leading-none" /></span>
                    {!collapsed && <span className="ml-4 min-w-[50px] whitespace-nowrap leading-none text-theme">{t('navbar.projects')}</span>}
                </li>
                <li className="flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer" onClick={() => navigate('/customers')}>
                    <span className="transition-none"><FiUsers className="text-[1.3rem] text-secondaryColor leading-none" /></span>
                    {!collapsed && <span className="ml-4 min-w-[50px] whitespace-nowrap leading-none text-theme">{t('navbar.customers')}</span>}
                </li>
                <li className="flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer relative" onClick={() => navigate('/messaging')}>
                    <span className="transition-none"><FiMessageSquare className="text-[1.3rem] text-secondaryColor leading-none" /></span>
                    {!collapsed && <span className="ml-4 min-w-[50px] whitespace-nowrap leading-none text-theme">{t('navbar.messaging')}</span>}
                    {hasUnreadMessages && (
                        <span className="absolute top-2 right-2 bg-red-500 text-white text-xs rounded-full w-3 h-3"></span>
                    )}
                </li>
                <li className="flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer" onClick={() => navigate('/documents')}>
                    <span className="transition-none"><FiFile className="text-[1.3rem] text-secondaryColor leading-none" /></span>
                    {!collapsed && <span className="ml-4 min-w-[50px] whitespace-nowrap leading-none text-theme">{t('navbar.documents')}</span>}
                </li>
                <li className="flex items-center pl-4 p-3 hover:bg-theme-hover rounded cursor-pointer" onClick={() => navigate('/settings')}>
                    <span className="transition-none"><FiSettings className="text-[1.3rem] text-secondaryColor leading-none" /></span>
                    {!collapsed && <span className="ml-4 min-w-[50px] whitespace-nowrap leading-none text-theme">{t('navbar.settings')}</span>}
                </li>
            </ul>
            <div className="absolute bottom-0 w-full">
                <button onClick={() => setIsModalOpen(!isModalOpen)}
                        className="p-2 w-full text-left hover:bg-theme-hover">
                    <FiUser />
                    {!collapsed && <span className="ml-2 text-theme">{t('navbar.profile')}</span>}
                </button>
                {isModalOpen && (
                    <div className="absolute bottom-12 left-0 w-full bg-theme p-4 rounded-lg">
                        <button
                            onClick={() => handleProfileChange('company')}
                            className="block w-full text-left p-2 hover:bg-theme-hover text-theme"
                        >
                            {t('profile.company')}
                        </button>
                        <button
                            onClick={() => handleProfileChange('customer')}
                            className="block w-full text-left p-2 hover:bg-theme-hover text-theme"
                        >
                            {t('profile.customer')}
                        </button>
                        <hr className="my-2" />
                        <button onClick={handleLogout} className="block w-full text-left p-2 hover:bg-theme-hover text-theme">
                            {t('navbar.logout')}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NavbarSide;