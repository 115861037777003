import React, { useEffect, useState, ReactNode, Children, isValidElement } from 'react';
import { FiChevronRight, FiArrowLeft } from 'react-icons/fi';

interface SidePanelProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    showOverlay?: boolean;
    animate?: boolean;
    currentPageIndex?: number;
    setCurrentPageIndex?: (index: number) => void;
    onValidate?: () => void; // Fonction de validation en fonction de la page
    isLoading?: boolean; // Ajout de isLoading pour gérer l'état de chargement
}

const SidePanel: React.FC<SidePanelProps> = ({ isOpen, onClose, children, showOverlay = true, animate = true, currentPageIndex = 0, setCurrentPageIndex, onValidate, isLoading = false }) => {
    const [isFullyClosed, setIsFullyClosed] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setIsFullyClosed(false);
            if (animate) {
                setTimeout(() => {
                    setIsAnimating(true);
                }, 10);
            } else {
                setIsAnimating(true);
            }
        } else {
            setIsAnimating(false);
            const timer = setTimeout(() => {
                setIsFullyClosed(true);
            }, animate ? 300 : 0);
            return () => clearTimeout(timer);
        }
    }, [isOpen, animate]);

    if (isFullyClosed && !isOpen) return null;

    const pages = Children.toArray(children).filter(
        (child) => isValidElement(child) && (child.props as { [key: string]: any })['data-page'] !== undefined
    );

    const handleNextPage = () => {
        if (setCurrentPageIndex && currentPageIndex < pages.length - 1) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };

    const handlePreviousPage = () => {
        if (setCurrentPageIndex && currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const currentChild = pages[currentPageIndex];
    const hasCustomNavigation = isValidElement(currentChild) && (currentChild.props as { [key: string]: any })['data-has-navigation'];

    // Déterminer le texte du bouton de validation à partir de l'attribut data-validation-text
    const validationButtonText = isLoading ? 'En cours...' : (isValidElement(currentChild)
        ? (currentChild.props as { [key: string]: any })['data-validation-text'] || 'Valider'
        : 'Valider');

    return (
        <>
            {showOverlay && (
                <div
                    className={`fixed inset-0 bg-white/10 backdrop-blur z-40 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                    onClick={onClose}
                ></div>
            )}

            <div
                className={`fixed right-4 top-4 h-[calc(100%-2rem)] w-1/3 bg-white border-theme border-2 rounded-lg z-50 ${
                    animate ? `transform transition-transform duration-300 ease-in-out ${isAnimating ? 'translate-x-0' : 'translate-x-full'}` : ''
                }`}
                style={!animate ? {right: 0, transform: 'none'} : {}}
            >
                <div className="flex justify-between items-center p-4">
                    {currentPageIndex > 0 && (
                        <button
                            onClick={handlePreviousPage}
                            className="text-base text-indigo-600 hover:text-indigo-800 focus:outline-none"
                        >
                            <div className="flex items-center">
                                <FiArrowLeft className="mr-2"
                                             size={16}/> {/* Ajuste la taille pour correspondre visuellement au texte */}
                                <span
                                    className="text-base leading-none">Retour</span> {/* Utilise leading-none pour retirer l'espace vertical supplémentaire */}
                            </div>
                        </button>
                    )}

                    {currentPageIndex === 0 && <div></div>}

                    <button
                        onClick={onClose}
                        className="w-8 h-8 flex items-center justify-center rounded-lg border border-gray-300 text-gray-800 focus:outline-none"
                    >
                        <FiChevronRight size={12}/>
                    </button>
                </div>

                <div className="p-6 overflow-y-auto h-[calc(100%-8rem)]">
                    {currentChild}

                    {setCurrentPageIndex && pages.length > 1 && (
                        <div className="mt-6 flex justify-between">
                            {!hasCustomNavigation && currentPageIndex < pages.length - 1 && (
                                <button
                                    onClick={handleNextPage}
                                    className="ml-auto px-4 py-2 bg-indigo-600 text-white rounded"
                                >
                                    Suivant
                                </button>
                            )}
                        </div>
                    )}
                </div>

                <div className="p-4 border-t border-gray-200">
                    <div className="flex justify-end">
                        <button
                            onClick={onValidate}
                            disabled={isLoading}
                            className={`py-2 px-4 text-white rounded focus:outline-none ${isLoading ? 'bg-gray-400' : 'bg-primaryColor'}`}
                        >
                            {validationButtonText}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidePanel;