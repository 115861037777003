import qs from "qs";
import axiosInstance from "./apiService";

export const createCompany = (companyData: any) => {
    const data = qs.stringify(companyData);
    return axiosInstance.post('/v1/companies', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export const getCompanyInfo = (companyUUID: string) => {
    return axiosInstance.get(`/v1/companies/${companyUUID}`);
};

export const updateCompanyInfo = (companyUUID: string, companyData: any) => {
    const data = qs.stringify(companyData);
    return axiosInstance.patch(`/v1/companies/${companyUUID}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};