import React from 'react';
import Skeleton from '../../../components/Skeleton';

interface ThreadHeaderProps {
    loading: boolean;
    thread: any;  // Vous pouvez affiner le type selon votre besoin
    project: any; // Idem
}

const ThreadHeader: React.FC<ThreadHeaderProps> = ({ loading, thread, project }) => {
    if (loading && !thread) {
        return (
            <div className="p-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    if (!thread) {
        return <div>Thread non trouvé.</div>;
    }

    return (
        <div className="p-4">
            <h1 className="text-xl font-bold">{thread.title}</h1>
            {project && <p>{project.name}</p>}
        </div>
    );
};

export default ThreadHeader;