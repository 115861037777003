import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { register, login, getUserInfo, updateUserInfo, associateToCustomer } from '../../api/authService';
import { useDispatch } from 'react-redux';
import { setUser, setLoading } from './authSlice';
import { setCompany } from '../company/companySlice';
import axiosInstance from '../../api/apiService';

const RegisterForm: React.FC = () => {
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        plainPassword: '',
        confirmPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const invitationToken = searchParams.get('invitationToken');
    const profileSelected = searchParams.get('profileSelected');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validatePassword = () => {
        const { plainPassword, confirmPassword } = formData;

        const hasUpperCase = /[A-Z]/.test(plainPassword);
        const hasLowerCase = /[a-z]/.test(plainPassword);
        const hasNumber = /\d/.test(plainPassword);
        const isLongEnough = plainPassword.length >= 6;

        if (plainPassword !== confirmPassword) {
            setPasswordError('Les mots de passe ne correspondent pas.');
            return false;
        } else if (!hasUpperCase || !hasLowerCase || !hasNumber || !isLongEnough) {
            setPasswordError('Le mot de passe doit contenir au moins 6 caractères, avec une majuscule, une minuscule et un nombre.');
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validatePassword()) {
            return;
        }

        setIsLoading(true);
        dispatch(setLoading(true));
        try {
            const { email, firstName, lastName, plainPassword } = formData;
            const response = await register({ email, firstName, lastName, plainPassword });

            if (response.status === 201 || response.status === 200) {
                const loginResponse = await login({
                    username: email,
                    password: plainPassword
                });

                if (loginResponse.status === 200) {
                    dispatch(setUser(loginResponse.data));

                    const userInfoResponse = await getUserInfo();
                    if (userInfoResponse.data) {
                        dispatch(setUser(userInfoResponse.data));
                        if (userInfoResponse.data.company) {
                            dispatch(setCompany(userInfoResponse.data.company));
                            sessionStorage.setItem('company', JSON.stringify(userInfoResponse.data.company));
                            navigate('/dashboard');
                        } else {
                            dispatch(setCompany(null));
                            sessionStorage.setItem('company', 'null');
                            navigate('/create-company');
                        }

                        // Si des paramètres sont présents dans l'URL, gérer les actions associées
                        if (profileSelected && profileSelected === 'customer') {
                            await handleProfileChange('customer');
                        }

                        if (invitationToken) {
                            await handleAssociateToCustomer(invitationToken);
                        }
                    }
                } else {
                    setMessage('Erreur de connexion après inscription');
                }
            } else {
                setMessage('Erreur de création de compte');
            }
        } catch (error) {
            setMessage('Erreur de création de compte');
        } finally {
            setIsLoading(false);
            dispatch(setLoading(false));
        }
    };

    const handleProfileChange = async (profile: 'company' | 'customer') => {
        try {
            const updatedUser = {
                ...formData,
                profileSelected: profile,
            };
            const response = await updateUserInfo(updatedUser);
            dispatch(setUser(response.data));
        } catch (error) {
            console.error("Erreur lors de la mise à jour du profil:", error);
        }
    };

    // http://localhost:3000/signup?profileSelected=customer&invitationToken=

    const handleAssociateToCustomer = async (token: string) => {
        try {
            const response = await associateToCustomer(token);
            console.log('API response:', response.data);
            alert('Association réussie');
        } catch (error) {
            console.error('Erreur lors de l\'association du client', error);
            alert('Erreur lors de l\'association');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                    Créer un compte
                </h2>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input
                                name="email"
                                type="email"
                                required
                                placeholder="Adresse email"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                name="firstName"
                                type="text"
                                required
                                placeholder="Prénom"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                name="lastName"
                                type="text"
                                required
                                placeholder="Nom de famille"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                name="plainPassword"
                                type="password"
                                required
                                placeholder="Mot de passe"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                name="confirmPassword"
                                type="password"
                                required
                                placeholder="Confirmez le mot de passe"
                                onChange={handleChange}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            />
                        </div>
                    </div>
                    {passwordError && (
                        <div className="text-center mt-4 text-red-500">
                            {passwordError}
                        </div>
                    )}
                    {!passwordError && message && (
                        <div className="text-center mt-4 text-red-500">
                            {message}
                        </div>
                    )}
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                                    ></path>
                                </svg>
                            ) : (
                                "S'inscrire"
                            )}
                        </button>
                    </div>

                    <div className="text-center mt-4">
                        <button type="button" onClick={() => navigate('/login')}
                                className="text-indigo-600 hover:text-indigo-500">
                            Déjà un compte ? Connectez-vous
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;
