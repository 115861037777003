import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDocument } from './documentSlice';
import { AppDispatch, RootState } from '../../store/store';
import { fetchProjects, selectProjects } from '../project/projectSlice'; // Import des projets

interface DocumentFormProps {
    onSuccess: () => void;
    documentData?: any;
    isEditMode: boolean;
    projectUuid: string;
}

const DocumentForm: React.FC<DocumentFormProps> = ({ onSuccess, documentData, isEditMode }) => {
    const [title, setTitle] = useState(documentData?.title || '');
    const [file, setFile] = useState<File | null>(null);
    const [projectUuid, setProjectUuid] = useState<string>(''); // Pour stocker l'UUID du projet sélectionné
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector((state: RootState) => selectProjects(state)); // Sélection des projets

    useEffect(() => {
        // Récupérer la liste des projets au montage du composant
        dispatch(fetchProjects());
    }, [dispatch]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        setFile(selectedFile);
    };

    const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setProjectUuid(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!projectUuid) {
            alert("Veuillez sélectionner un projet");
            return;
        }

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', file!);

            // Ajouter title seulement s'il n'est pas vide
            if (title.trim()) {
                formData.append('title', title);
            }

            await dispatch(addDocument({ documentData: formData, projectUuid }));
            onSuccess();
        } catch (error) {
            console.error("Erreur lors de la soumission du document :", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                    Titre (optionnel)
                </label>
                <input
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="project">
                    Projet
                </label>
                <select
                    id="project"
                    value={projectUuid}
                    onChange={handleProjectChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                >
                    <option value="">Sélectionnez un projet</option>
                    {projects.map((project) => (
                        <option key={project.uuid} value={project.uuid}>
                            {project.title}
                        </option>
                    ))}
                </select>
            </div>

            {!isEditMode && (
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="file">
                        Fichier
                    </label>
                    <input
                        id="file"
                        type="file"
                        onChange={handleFileChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
            )}

            <div className="flex items-center justify-between">
                <button
                    type="submit"
                    className="px-4 py-2 bg-indigo-600 text-white rounded"
                    disabled={loading}
                >
                    {loading ? 'En cours...' : isEditMode ? 'Mettre à jour' : 'Créer'}
                </button>
            </div>
        </form>
    );
};

export default DocumentForm;