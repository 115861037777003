import axiosInstance from './apiService';

// Interface pour un document
interface DocumentData {
    title: string;
    content: string;
}

// Fonction pour récupérer un document spécifique
export const getDocument = (uuid: string) => {
    return axiosInstance.get(`/v1/documents/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération du document:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour supprimer un document spécifique
export const deleteDocument = (uuid: string) => {
    return axiosInstance.delete(`/v1/documents/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la suppression du document:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour lister les documents de l'utilisateur connecté
export const getUserDocuments = () => {
    return axiosInstance.get('/v1/documents')
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des documents de l\'utilisateur:', error.response?.data || error.message);
            throw error;
        });
};

// Fonction pour uploader un nouveau document avec un projectUuid
export const uploadDocument = async (formData: FormData, projectUuid: string) => {
    try {
        formData.append('project', projectUuid);
        const response = await axiosInstance.post('/v1/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response; // Assurez-vous que la réponse contient bien l'UUID du document
    } catch (error) {
        console.error('Erreur lors de l\'upload du document:', error);
        throw error; // Renvoyer l'erreur pour qu'elle soit capturée dans handleSendMessage
    }
};