import React from 'react';

interface DateInputProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput: React.FC<DateInputProps> = ({ label, name, value, onChange }) => {
    return (
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
                {label}
            </label>
            <input
                type="date"
                name={name}
                value={value}
                onChange={onChange}
                className="appearance-none block w-full border border-gray-300 rounded-lg px-4 py-2 leading-tight focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1"
            />
        </div>
    );
};

export default DateInput;