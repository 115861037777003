import React, { useEffect, useState, useRef } from 'react';

interface DropOverlayProps {
    setFile: (file: File | null) => void;
}

const DropOverlay: React.FC<DropOverlayProps> = ({ setFile }) => {
    const [isDragging, setIsDragging] = useState(false); // État pour savoir si un fichier est en train d'être glissé
    const dragCounter = useRef(0); // Compteur pour gérer les entrées et sorties de drag

    useEffect(() => {
        const handleDragEnter = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter.current += 1; // Incrémenter le compteur à chaque dragenter
            if (dragCounter.current === 1) {
                setIsDragging(true); // Afficher la zone de dépôt si le compteur est à 1
            }
        };

        const handleDragOver = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDragLeave = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter.current -= 1; // Décrémenter le compteur à chaque dragleave
            if (dragCounter.current === 0) {
                setIsDragging(false); // Cacher la zone de dépôt si le compteur est à 0
            }
        };

        const handleDrop = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false); // Cacher la zone de dépôt après le drop
            dragCounter.current = 0; // Réinitialiser le compteur après le drop

            // Récupérer le fichier déposé et mettre à jour l'état
            if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
                setFile(e.dataTransfer.files[0]); // Mettre à jour l'état avec le fichier sélectionné
                e.dataTransfer.clearData();
            }
        };

        // Ajouter les écouteurs d'événements sur la fenêtre
        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);

        // Nettoyer les écouteurs d'événements lors de la destruction du composant
        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
        };
    }, [setFile]);

    return (
        <>
            {/* Affichage de la zone de dépôt couvrant tout l'écran */}
            {isDragging && (
                <div className="fixed inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
                    <p className="text-lg font-semibold text-gray-600">Déposez le document ici</p>
                </div>
            )}
        </>
    );
};

export default DropOverlay;