import { useEffect, useState } from 'react';

export const useTheme = () => {
    const [theme, setTheme] = useState<string>(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme || 'light'; // Utilise 'light' par défaut si aucun thème n'est sauvegardé
    });

    const applyTheme = (selectedTheme: string) => {
        if (selectedTheme === 'system') {
            const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            document.documentElement.classList.remove('dark', 'light');
            document.documentElement.classList.add(systemTheme);
        } else {
            document.documentElement.classList.remove('dark', 'light');
            document.documentElement.classList.add(selectedTheme);
        }
    };

    useEffect(() => {
        applyTheme(theme);

        if (theme === 'system') {
            const systemThemeListener = window.matchMedia('(prefers-color-scheme: dark)');
            const systemThemeChangeHandler = (e: MediaQueryListEvent) => {
                const newTheme = e.matches ? 'dark' : 'light';
                document.documentElement.classList.remove('dark', 'light');
                document.documentElement.classList.add(newTheme);
            };

            systemThemeListener.addEventListener('change', systemThemeChangeHandler);

            return () => {
                systemThemeListener.removeEventListener('change', systemThemeChangeHandler);
            };
        }

        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = (newTheme: string) => {
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        applyTheme(newTheme); // Applique immédiatement le nouveau thème
    };

    return { theme, toggleTheme };
};