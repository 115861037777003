import React from 'react';

interface InputTextProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
}

const InputText: React.FC<InputTextProps> = ({ label, name, value, onChange, placeholder, required = false }) => {
    return (
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
                {label}{required && '*'}
            </label>
            <input
                type="text"
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className="appearance-none block w-full border border-gray-300 rounded-lg px-4 py-2 leading-tight focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1"
                required={required}
            />
        </div>
    );
};

export default InputText;