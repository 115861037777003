import React, { useState, useEffect, useRef } from 'react';
import { FiPlus } from 'react-icons/fi'; // Import de l'icône plus

interface SearchableSelectProps {
    label: string;
    name: string;
    value: string;
    options: { value: string; label: string }[];
    onChange: (value: string) => void;
    onButtonClick: () => void;
    buttonLabel: string;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
   label,
   name,
   value,
   options,
   onChange,
   onButtonClick,
   buttonLabel
}) => {
    const [query, setQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);

    // Ferme les propositions si l'on clique en dehors de l'input
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [inputRef]);

    // Si l'input est vide, affiche tous les clients, sinon filtre
    const filteredOptions = query === ''
        ? options
        : options.filter(option =>
            option.label.toLowerCase().includes(query.toLowerCase())
        );

    return (
        <div className="mb-4 relative" ref={inputRef}>
            <label className="block text-gray-700 text-sm font-medium mb-2">{label}</label>
            <div className="flex items-center relative">
                <input
                    type="text"
                    name={name}
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        setIsOpen(true);
                        onChange(e.target.value);
                    }}
                    onFocus={() => setIsOpen(true)}
                    className="w-full border border-gray-300 rounded-lg px-4 py-2 leading-tight focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1"
                    placeholder="Rechercher un client"
                    style={{ paddingRight: '80px' }} // Pour laisser de l'espace pour le bouton
                />
                <button
                    type="button"
                    onClick={onButtonClick}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 px-3 py-1 bg-gray-200 text-white text-xs rounded-lg flex items-center space-x-1"
                    style={{ whiteSpace: 'nowrap' }} // Empêche la rupture du texte
                >
                    <FiPlus className="text-theme"/> {/* Icône + */}
                    <span className="text-theme">{buttonLabel}</span>
                </button>
            </div>

            {isOpen && (
                <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-b-lg mt-0 max-h-48 w-full overflow-y-auto z-10">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                className="px-4 py-2 hover:bg-indigo-100 cursor-pointer"
                                onClick={() => {
                                    setQuery(option.label);
                                    setIsOpen(false);
                                    onChange(option.value);
                                }}
                            >
                                {option.label}
                            </li>
                        ))
                    ) : (
                        <li className="px-4 py-2 text-gray-500">Aucune option disponible</li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default SearchableSelect;