import React from 'react';
import ThreadList from '../features/messaging/ThreadList';
import ThreadContainer from '../features/messaging/ThreadContainer';
import { Routes, Route, useParams } from 'react-router-dom';

const MessagingPage: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();

    return (
        <Routes>
            {/* Si un uuid est présent, on affiche les détails du thread */}
            <Route path="/" element={<ThreadList />} />
            <Route path=":uuid" element={<ThreadContainer />} />
        </Routes>
    );
};

export default MessagingPage;
