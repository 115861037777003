import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

interface Company {
    uuid: string;
    legalName: string;
    commercialName: string;
    siret: string;
    vatNumber?: string;
    mainColor?: string;
    secondaryColor?: string;
    logo?: string;
}

interface CompanyState {
    company: Company | null;
    loading: boolean;
    error: string | null;
}

const initialState: CompanyState = {
    company: null,
    loading: false,
    error: null,
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompany: (state, action: PayloadAction<Company | null>) => {
            state.company = action.payload;
            state.loading = false;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        clearCompany: (state) => {
            state.company = null;
            state.loading = false;
            state.error = null;
        },
    },
});

export const { setCompany, setLoading, setError, clearCompany } = companySlice.actions;

export const selectCompany = (state: RootState) => state.company.company;
export const selectCompanyLoading = (state: RootState) => state.company.loading;
export const selectCompanyError = (state: RootState) => state.company.error;

export default companySlice.reducer;
