import React from 'react';
import { useTheme } from '../../../hooks/useTheme';
import { useTranslation } from 'react-i18next';

const ThemeSelector: React.FC = () => {
    const { t } = useTranslation();
    const { theme, toggleTheme } = useTheme(); // Utilisation du hook useTheme

    return (
        <div className="mt-4">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('settings.theme')}
            </label>
            <div className="mt-2 flex space-x-2">
                <button
                    type="button"
                    className={`py-2 px-4 rounded-md text-sm font-medium focus:outline-none ${theme === 'light' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => toggleTheme('light')}
                >
                    {t('settings.light')}
                </button>
                <button
                    type="button"
                    className={`py-2 px-4 rounded-md text-sm font-medium focus:outline-none ${theme === 'dark' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => toggleTheme('dark')}
                >
                    {t('settings.dark')}
                </button>
                <button
                    type="button"
                    className={`py-2 px-4 rounded-md text-sm font-medium focus:outline-none ${theme === 'system' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => toggleTheme('system')}
                >
                    {t('settings.system')}
                </button>
            </div>
        </div>
    );
};

export default ThemeSelector;