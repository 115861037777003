import React from 'react';
import { useTranslation } from 'react-i18next';

interface SettingsNavbarProps {
    activeTab: string;
    onSelectTab: (tab: string) => void;
}

const SettingsNavbar: React.FC<SettingsNavbarProps> = ({ activeTab, onSelectTab }) => {
    const { t } = useTranslation();

    const tabs = [
        { name: t('settings.profile'), value: 'profile' },  // Traduction pour 'Profile'
        { name: t('settings.security'), value: 'security' },  // Traduction pour 'Profile'
        { name: t('settings.company'), value: 'company' },  // Traduction pour 'Company'
        { name: t('settings.appearance'), value: 'appearance' },  // Traduction pour 'Appearance'
        { name: t('settings.language'), value: 'language' },  // Traduction pour 'Language'
        // Ajoutez ici toutes les catégories que vous souhaitez conserver
    ];

    return (
        <div className="">
            <div className="flex space-x-4">
                {tabs.map((tab) => (
                    <button
                        key={tab.value}
                        className={`whitespace-nowrap py-2 px-4 rounded-md text-sm font-medium ${
                            activeTab === tab.value
                                ? 'bg-gray-100 text-black'
                                : 'text-gray-500 hover:bg-gray-100'
                        }`}
                        onClick={() => onSelectTab(tab.value)}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SettingsNavbar;