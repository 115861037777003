import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = React.useState<string>(i18n.language);

    const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newLanguage = e.target.value;
        i18n.changeLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        setLanguage(newLanguage);
    };

    return (
        <form className="max-w-sm ml-0 mt-4">
            <label
                htmlFor="language"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                {t('settings.language')}
            </label>
            <select
                id="language"
                name="language"
                value={language}
                onChange={handleLanguageChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
                <option value="en">{t('languages.english')}</option>
                <option value="fr">{t('languages.french')}</option>
            </select>
        </form>
    );
};

export default LanguageSelector;