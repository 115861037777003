import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import Skeleton from '../components/Skeleton';
import { useTranslation } from 'react-i18next';
import { selectCompanyLoading } from '../features/company/companySlice';
import { selectProjectsLoading, selectProjects } from '../features/project/projectSlice';
import { selectCustomersLoading, selectCustomers } from '../features/customer/customerSlice';
import {formatPrice} from "../utils/formatPrice";

const DashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const company = useSelector((state: RootState) => state.company.company);
    const user = useSelector((state: RootState) => state.auth.user);
    const userLoading = useSelector((state: RootState) => state.auth.loading);
    const companyLoading = useSelector(selectCompanyLoading);
    const projectsLoading = useSelector(selectProjectsLoading);
    const customersLoading = useSelector(selectCustomersLoading);
    const projects = useSelector(selectProjects);
    const customers = useSelector(selectCustomers);

    // On combine tous les états de chargement : utilisateur, société, projets et clients
    const isLoading = userLoading || companyLoading || projectsLoading || customersLoading;

    // Calcul du prix total de tous les projets
    const totalProfits = projects.reduce((total, project) => total + (project.amount || 0), 0);

    return (
        <div className="flex h-full">
            <div className="flex-1">
                <div className="pl-6 p-4">
                    <h2 className="text-theme text-xl font-bold mb-4 flex items-center ease-in-out">
                        {t('dashboard.hello')}, {isLoading ? <Skeleton width="15%" height="20px" /> : user?.firstName}
                    </h2>
                </div>
                <div className="pl-6 p-4">
                    <h2 className="text-theme text-xl font-bold mb-4 ease-in-out">{t('dashboard.statistics')}</h2>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                            <h3 className="text-theme text-lg font-bold">{t('dashboard.profits')}</h3>
                            {isLoading ? <Skeleton width="100%" height="20px" /> : <p className="text-theme">€ {formatPrice(totalProfits)}</p>} {/* Affiche les bénéfices totaux */}
                        </div>
                        <div className="p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                            <h3 className="text-theme text-lg font-bold">{t('dashboard.projects')}</h3>
                            {isLoading ? <Skeleton width="100%" height="20px" /> : <p className="text-theme">{projects.length}</p>}
                        </div>
                        <div className="p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                            <h3 className="text-theme text-lg font-bold">{t('dashboard.customers')}</h3>
                            {isLoading ? <Skeleton width="100%" height="20px" /> : <p className="text-theme">{customers.length}</p>}
                        </div>
                    </div>
                    <div className="mt-8 p-4 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                        <h3 className="text-theme text-lg font-bold">{t('dashboard.userInfo')}</h3>
                        {isLoading ? (
                            <>
                                <Skeleton width="100%" height="20px" />
                                <Skeleton width="100%" height="20px" />
                                <Skeleton width="100%" height="20px" />
                                <Skeleton width="100%" height="20px" />
                            </>
                        ) : (
                            <>
                                <p className="text-theme">
                                    <strong>{t('user.email')}:</strong> {user?.email}
                                </p>
                                <p className="text-theme">
                                    <strong>{t('user.firstName')}:</strong> {user?.firstName}
                                </p>
                                <p className="text-theme">
                                    <strong>{t('user.lastName')}:</strong> {user?.lastName}
                                </p>
                                <p className="text-theme">
                                    <strong>{t('user.lastLogin')}:</strong> {user?.lastLogin}
                                </p>
                            </>
                        )}
                        {company && (
                            <>
                                <h3 className="text-theme text-lg font-bold">{t('dashboard.companyInfo')}</h3>
                                {isLoading ? (
                                    <>
                                        <Skeleton width="100%" height="20px" />
                                        <Skeleton width="100%" height="20px" />
                                        <Skeleton width="100%" height="20px" />
                                    </>
                                ) : (
                                    <>
                                        <p className="text-theme">
                                            <strong>{t('company.legalName')}:</strong> {company.legalName}
                                        </p>
                                        <p className="text-theme">
                                            <strong>{t('company.commercialName')}:</strong> {company.commercialName}
                                        </p>
                                        <p className="text-theme">
                                            <strong>{t('company.siret')}:</strong> {company.siret}
                                        </p>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;