import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsNavbar from '../features/settings/components/SettingsNavbar';
import UserInformationForm from '../features/settings/components/UserInformationForm';
import CompanyInformationForm from '../features/settings/components/CompanyInformationForm';
import ThemeSelector from '../features/settings/components/ThemeSelector';
import LanguageSelector from '../features/settings/components/LanguageSelector';
import SecurityForm from '../features/settings/components/SecurityForm';

const SettingsPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const initialTab = queryParams.get('tab') || 'profile'; // 'profile' est la valeur par défaut

    const [activeTab, setActiveTab] = useState<string>(initialTab);

    useEffect(() => {
        navigate(`/settings?tab=${activeTab}`, { replace: true });
    }, [activeTab, navigate]);

    const renderContent = () => {
        switch (activeTab) {
            case 'profile':
                return <UserInformationForm />;
            case 'security':
                return <SecurityForm />;
            case 'company':
                return <CompanyInformationForm />;
            case 'appearance':
                return <ThemeSelector />;
            case 'language':
                return <LanguageSelector />;
            default:
                return null;
        }
    };

    return (
        <div className="pl-6 p-4">
            <SettingsNavbar activeTab={activeTab} onSelectTab={setActiveTab} />
            <div className="mt-8">
                {renderContent()}
            </div>
        </div>
    );
};

export default SettingsPage;