import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, deleteCustomer, selectCustomers, selectCustomersLoading } from './customerSlice';
import { AppDispatch } from '../../store/store';
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import { FiMoreVertical, FiList, FiGrid } from 'react-icons/fi';

interface CustomerListProps {
    onEditCustomer: (customer: any) => void;
    onViewDetails: (uuid: string) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({ onEditCustomer, onViewDetails }) => {
    const dispatch = useDispatch<AppDispatch>();

    const customers = useSelector(selectCustomers);
    const loading = useSelector(selectCustomersLoading);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [customerToDelete, setCustomerToDelete] = useState<any | null>(null);
    const [deleting, setDeleting] = useState(false);
    const [viewType, setViewType] = useState<'list' | 'kanban'>('list');
    const [hoveredCustomer, setHoveredCustomer] = useState<string | null>(null);

    useEffect(() => {
        if (customers.length === 0) {
            dispatch(fetchCustomers());
        }
    }, [dispatch, customers.length]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest('.hover-menu')) {
                setHoveredCustomer(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDeleteClick = (customer: any) => {
        setCustomerToDelete(customer);
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (customerToDelete) {
            setDeleting(true);
            await dispatch(deleteCustomer(customerToDelete.uuid));
            setDeleting(false);
            setShowConfirmModal(false);
            setCustomerToDelete(null);
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
        setCustomerToDelete(null);
    };

    return (
        <div>
            {/* Vue type selector */}
            <div className="mb-2 flex justify-start items-center">
                <div className="inline-flex rounded-md">
                    <button
                        type="button"
                        className={`px-4 py-2 rounded-l-md border border-gray-300 text-sm ${viewType === 'list' ? 'bg-white shadow' : 'bg-gray-100'}`}
                        onClick={() => setViewType('list')}
                    >
                        <FiList className={`text-base ${viewType === 'list' ? 'text-black' : 'text-gray-500'}`} />
                    </button>
                    <button
                        type="button"
                        className={`px-4 py-2 rounded-r-md border border-gray-300 text-sm ${viewType === 'kanban' ? 'bg-white shadow' : 'bg-gray-100'}`}
                        onClick={() => setViewType('kanban')}
                    >
                        <FiGrid className={`text-base ${viewType === 'kanban' ? 'text-black' : 'text-gray-500'}`} />
                    </button>
                </div>
            </div>

            {loading ? (
                <div className="grid grid-cols-3 gap-4">
                    {[...Array(6)].map((_, i) => (
                        <div key={i} className="bg-white p-4 rounded shadow">
                            <Skeleton height="20px" width="70%" />
                            <Skeleton height="20px" width="50%" />
                            <Skeleton height="30px" width="100%" />
                        </div>
                    ))}
                </div>
            ) : (
                customers.length === 0 ? (
                    <p>Aucun client trouvé.</p>
                ) : (
                    viewType === 'list' ? (
                        // Vue liste
                        <div className="space-y-4">
                            {customers.map((customer: any) => (
                                <div
                                    key={customer.uuid}
                                    className="bg-white p-4 rounded border-theme border-2 flex justify-between items-center cursor-pointer"
                                    onClick={() => onViewDetails(customer.uuid)}
                                >
                                    <div>
                                        <h3 className="text-lg font-bold">{customer.companyName || 'Nom de l\'entreprise non renseigné'}</h3>
                                        <p>{customer.firstName} {customer.lastName}</p>
                                    </div>

                                    <div className="relative hover-menu">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setHoveredCustomer(hoveredCustomer === customer.uuid ? null : customer.uuid);
                                            }}
                                            className="w-8 h-8 flex justify-center items-center rounded-full bg-gray-200 text-gray-600 hover:text-gray-900"
                                        >
                                            <FiMoreVertical />
                                        </button>

                                        {hoveredCustomer === customer.uuid && (
                                            <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-md z-10 hover-menu">
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditCustomer(customer);
                                                        setHoveredCustomer(null);
                                                    }}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteClick(customer);
                                                        setHoveredCustomer(null);
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        // Vue Kanban
                        <div className="grid grid-cols-3 gap-4">
                            {customers.map((customer: any) => (
                                <div
                                    key={customer.uuid}
                                    className="bg-white p-4 rounded border-theme border-2 flex justify-between items-center cursor-pointer"
                                    onClick={() => onViewDetails(customer.uuid)}
                                >
                                    <div>
                                        <h3 className="text-lg font-bold">{customer.companyName || 'Nom de l\'entreprise non renseigné'}</h3>
                                        <p>{customer.firstName} {customer.lastName}</p>
                                    </div>

                                    <div className="relative hover-menu">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setHoveredCustomer(hoveredCustomer === customer.uuid ? null : customer.uuid);
                                            }}
                                            className="w-8 h-8 flex justify-center items-center rounded-full bg-gray-200 text-gray-600 hover:text-gray-900"
                                        >
                                            <FiMoreVertical />
                                        </button>

                                        {hoveredCustomer === customer.uuid && (
                                            <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded shadow-md z-10 hover-menu">
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditCustomer(customer);
                                                        setHoveredCustomer(null);
                                                    }}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteClick(customer);
                                                        setHoveredCustomer(null);
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                )
            )}

            {/* Modal de confirmation de suppression */}
            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p>Êtes-vous sûr de vouloir supprimer le client suivant ?</p>
                {customerToDelete && (
                    <p className="font-bold">{customerToDelete.firstName} {customerToDelete.lastName}</p>
                )}
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete} disabled={deleting}>
                        Annuler
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded flex items-center" onClick={confirmDelete} disabled={deleting}>
                        {deleting ? (
                            <svg className="animate-spin h-5 w-5 text-white mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : 'Supprimer'}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default CustomerList;