import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { createCustomer, updateCustomer } from './customerSlice';
import { AppDispatch } from '../../store/store';

// Import des composants réutilisables
import InputText from '../../components/InputText';
import SearchableSelect from "../../components/SearchableSelect";
import InputEmail from "../../components/InputEmail";
import InputPhone from "../../components/InputPhone";

interface CustomerFormProps {
    onSuccess: (customerUuid?: string) => void;
    customerData?: {
        uuid: string;
        companyName: string;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    } | null;
    isEditMode?: boolean;
    isLoading: boolean;  // Nouveau prop pour gérer l'état de chargement
}

const CustomerForm = forwardRef((props: CustomerFormProps, ref) => {
    const { onSuccess, customerData, isEditMode = false, isLoading } = props;
    const dispatch = useDispatch<AppDispatch>();

    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        if (customerData) {
            setFormData({
                companyName: customerData.companyName || '',
                firstName: customerData.firstName || '',
                lastName: customerData.lastName || '',
                email: customerData.email || '',
                phone: customerData.phone || ''
            });
        }
    }, [customerData]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async () => {
        setMessage(null);

        try {
            let resultAction;
            if (isEditMode && customerData?.uuid) {
                resultAction = await dispatch(updateCustomer({ uuid: customerData.uuid, customerData: formData }));
            } else {
                resultAction = await dispatch(createCustomer(formData));
            }

            if ((isEditMode && updateCustomer.fulfilled.match(resultAction)) || createCustomer.fulfilled.match(resultAction)) {
                const newCustomerUuid = createCustomer.fulfilled.match(resultAction) ? resultAction.payload.uuid : undefined;
                onSuccess(newCustomerUuid);
            } else {
                setMessage(`Erreur : ${resultAction.payload ?? `Échec de la ${isEditMode ? 'modification' : 'création'} du client`}`);
            }
        } catch (error) {
            setMessage(`Erreur lors de la ${isEditMode ? 'modification' : 'création'} du client : ${error}`);
        }
    };

    useImperativeHandle(ref, () => ({
        submitForm: handleSubmit,
    }));

    return (
        <div className="flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <h2 className="text-xl font-semibold">Ajouter un client</h2>
                <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                    <div>
                        <InputText
                            label="Nom de l'entreprise"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            placeholder="Entrez le nom de l'entreprise"
                        />
                        <InputText
                            label="Prénom"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="Entrez le prénom"
                        />
                        <InputText
                            label="Nom"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Entrez le nom"
                        />
                        <InputEmail
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Entrez l'adresse email"
                        />
                        <InputPhone
                            label="Téléphone"
                            name="phone"
                            value={formData.phone}
                            onChange={(newValue) => setFormData({ ...formData, phone: newValue })}
                            placeholder="Entrez votre numéro de téléphone"
                        />
                    </div>
                    {message && (
                        <div className="text-center mt-4 text-red-500">
                            {message}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
});

export default CustomerForm;