import React, { useState, useRef, useEffect } from 'react';

interface DragScrollContainerProps {
    children: React.ReactNode;
    className?: string;
}

const DragScrollContainer: React.FC<DragScrollContainerProps> = ({ children, className }) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [showLeftShadow, setShowLeftShadow] = useState(false);
    const [showRightShadow, setShowRightShadow] = useState(true); // True by default as there will likely be more content to scroll

    // Lorsque l'utilisateur clique et commence à glisser
    const handleMouseDown = (e: React.MouseEvent) => {
        if (scrollRef.current) {
            setIsDragging(true);
            setStartX(e.pageX - scrollRef.current.offsetLeft);
            setScrollLeft(scrollRef.current.scrollLeft);
            document.body.style.userSelect = 'none'; // Empêcher la sélection de texte pendant le drag
        }
    };

    // Lors du mouvement de la souris
    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !scrollRef.current) return;
        e.preventDefault(); // Empêcher le comportement par défaut
        const x = e.pageX - scrollRef.current.offsetLeft; // Position actuelle de la souris
        const walk = (x - startX); // Distance parcourue par la souris
        scrollRef.current.scrollLeft = scrollLeft - walk; // Mise à jour du défilement
        updateShadows();
    };

    // Lorsque l'utilisateur relâche la souris
    const handleMouseUp = () => {
        setIsDragging(false);
        document.body.style.userSelect = ''; // Réactiver la sélection de texte
    };

    // Lorsque la souris sort de la zone
    const handleMouseLeave = () => {
        setIsDragging(false);
        document.body.style.userSelect = ''; // Réactiver la sélection de texte si la souris quitte la zone
    };

    // Mettre à jour la visibilité des ombres en fonction de la position de défilement
    const updateShadows = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setShowLeftShadow(scrollLeft > 0); // Affiche l'ombre à gauche si ce n'est pas au tout début
            setShowRightShadow(scrollLeft + clientWidth < scrollWidth); // Affiche l'ombre à droite si ce n'est pas au bout
        }
    };

    // Ajoute un event listener sur le défilement
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', updateShadows);
        }
        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', updateShadows);
            }
        };
    }, []);

    return (
        <div className="relative">
            {/* Shadow blanc à gauche */}
            {showLeftShadow && (
                <div className="absolute left-0 top-0 h-full w-10 pointer-events-none bg-gradient-to-r from-white to-transparent z-10"></div>
            )}

            {/* Contenu scrollable */}
            <div
                ref={scrollRef}
                className={`overflow-x-scroll whitespace-nowrap scrollbar-hide relative z-0 ${className}`}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>

            {/* Shadow blanc à droite */}
            {showRightShadow && (
                <div className="absolute right-0 top-0 h-full w-10 pointer-events-none bg-gradient-to-l from-white to-transparent z-10"></div>
            )}
        </div>
    );
};

export default DragScrollContainer;