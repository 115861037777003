import React, { useEffect, useState } from 'react';

interface ThreadMessageItemProps {
    message: any; // Type à affiner
    user: any;  // Type à affiner
    handleDeleteMessage: (messageId: string) => void;
    isLastMessage: boolean;
}

const ThreadMessageItem: React.FC<ThreadMessageItemProps> = ({ message, user, handleDeleteMessage, isLastMessage }) => {
    const [showStatus, setShowStatus] = useState(false);

    console.log(message)

    useEffect(() => {
        if (message.status === 'pending' || message.status === 'sent' || message.status === 'failed') {
            setShowStatus(true);
            const timer = setTimeout(() => {
                setShowStatus(false);
            }, 10000); // Masquer après 10 secondes

            return () => clearTimeout(timer); // Cleanup le timer lorsque le composant est démonté ou que le statut change
        }
    }, [message.status]);

    return (
        <div
            className={`${
                message.isDocument
                    ? "text-center mx-auto my-2"
                    : "flex flex-col " + (message.sender?.uuid === user?.uuid ? "items-end" : "items-start mb-4")
            }`}
            style={{ position: 'relative' }} // Pour positionner le statut en flottant
        >
            {!message.isDocument ? (
                <div className="max-w-[70%] inline-block">
                    <p className="text-left mb-1 text-theme">
                        {message.sender?.uuid === user?.uuid ? "Vous" : message.sender?.firstName + " " + message.sender?.lastName}
                    </p>
                    <div
                        className={`bubble p-2 ${
                            message.sender?.uuid === user?.uuid
                                ? "bg-theme-extra-light text-theme rounded-l-lg"
                                : "bg-theme-extra-light text-theme rounded-r-lg"
                        } relative inline-block break-words min-w-[40px] max-w-full`}
                    >
                        <p>{message.content || message.body}</p>

                        {/* Affichage du statut en flottant uniquement si `showStatus` est vrai */}
                        {message.sender?.uuid === user?.uuid && showStatus && (
                            <span
                                className={`text-sm mt-1 text-right ${
                                    message.status === 'pending' ? 'text-gray-500' :
                                        message.status === 'sent' ? 'text-green-500' :
                                            'text-red-500'
                                }`}
                                style={{
                                    position: 'absolute',
                                    right: '0',  // Ajustez selon votre besoin
                                    bottom: '-25px', // Ajustez selon votre besoin
                                    whiteSpace: 'nowrap',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    padding: '2px 6px',
                                    borderRadius: '4px',
                                    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                {message.status === 'pending' && '•••'}
                                {message.status === 'sent' && '✓'}
                                {message.status === 'failed' && 'Échec de l\'envoi'}
                            </span>
                        )}
                    </div>
                    {message.sender?.uuid === user?.uuid && message.status === 'failed' && (
                        <button
                            onClick={() => handleDeleteMessage(message.uuid || '')}
                            className="mt-1 text-red-500"
                        >
                            Supprimer
                        </button>
                    )}
                </div>
            ) : (
                <p>{message.content}</p>
            )}
        </div>
    );
};

export default ThreadMessageItem;