import qs from "qs";
import axiosInstance from './apiService';

export const getAllProjects = () => {
    return axiosInstance.get('/v1/projects')
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des projets:', error);
            throw error;
        });
};

export const createProject = (projectData: any) => {
    const data = qs.stringify(projectData);
    return axiosInstance.post('/v1/projects', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la création du projet:', error);
        throw error;
    });
};

export const updateProject = (uuid: string, projectData: any) => {
    const data = qs.stringify(projectData);
    return axiosInstance.patch(`/v1/projects/${uuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la mise à jour du projet:', error);
        throw error;
    });
};

export const deleteProject = (uuid: string) => {
    return axiosInstance.delete(`/v1/projects/${uuid}`)
        .then(response => {
            return response;
        }).catch(error => {
            console.error('Erreur lors de la suppression du projet:', error);
            throw error;
        });
};

export const getProjectInfo = (uuid: string) => {
    return axiosInstance.get(`/v1/projects/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la récupération du projet avec l'UUID ${uuid}:`, error);
            throw error;
        });
};

export const getMissionsByProject = (projectUuid: string) => {
    return axiosInstance.get(`/v1/projects/${projectUuid}/missions`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la récupération des missions pour le projet ${projectUuid}:`, error);
            throw error;
        });
};

export const createMission = (projectUuid: string, missionData: any) => {
    const data = qs.stringify(missionData);
    return axiosInstance.post(`/v1/projects/${projectUuid}/missions`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la création de la mission:', error);
        throw error;
    });
};

export const updateMissionStatus = (projectUuid: string, missionUuid: string, status: string) => {
    const data = qs.stringify({ status }); // On ne met à jour que le statut
    return axiosInstance.patch(`/v1/projects/${projectUuid}/missions/${missionUuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error(`Erreur lors de la mise à jour du statut de la mission ${missionUuid}:`, error);
        throw error;
    });
};