import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { updateUserInfo } from '../../../api/authService';
import { setUser } from '../../auth/authSlice';
import Skeleton from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';

const SecurityForm: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.auth);

    const [formData, setFormData] = React.useState({
        plainPassword: '',
        confirmPassword: '',
    });

    const [loading, setLoading] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>('');

    React.useEffect(() => {
        if (user) {
            setFormData({
                plainPassword: '',
                confirmPassword: '',
            });
            setLoading(false);
        }
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Vérification des mots de passe
        if (formData.plainPassword !== formData.confirmPassword) {
            setMessage(t('security.passwordMismatch'));
            return;
        }

        try {
            // Mise à jour du mot de passe de l'utilisateur
            const response = await updateUserInfo({
                plainPassword: formData.plainPassword, // Envoi du nouveau mot de passe
            });

            dispatch(setUser(response.data));
            setMessage(t('security.updateSuccess'));
        } catch (error) {
            console.error(t('security.updateError'), error);
            setMessage(t('security.updateError'));
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-sm">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {t('security.changePassword')}
            </label>
            <div className="space-y-6">
                {loading ? (
                    <>
                        <Skeleton height="40px" />
                        <Skeleton height="40px" />
                    </>
                ) : (
                    <>
                        <div>
                            <input
                                name="plainPassword"
                                type="password"
                                required
                                className="block w-full px-3 py-2 border rounded-md"
                                placeholder={t('security.newPassword')}
                                value={formData.plainPassword}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <input
                                name="confirmPassword"
                                type="password"
                                required
                                className="block w-full px-3 py-2 border rounded-md"
                                placeholder={t('security.confirmPassword')}
                                value={formData.confirmPassword}
                                onChange={handleChange}
                            />
                        </div>
                    </>
                )}
                <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                    {t('settings.update')}
                </button>
                {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            </div>
        </form>
    );
};

export default SecurityForm;