import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { setUser } from '../../features/auth/authSlice';
import { setCompany } from '../../features/company/companySlice';
import { getUserInfo } from '../../api/authService';

const CompanyCheckRoute: React.FC = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const companyStored = sessionStorage.getItem('company');

        if (isAuthenticated && !companyStored) {
            const fetchUserInfo = async () => {
                try {
                    const response = await getUserInfo();
                    if (response.data) {
                        dispatch(setUser(response.data));
                        if (response.data.company) {
                            dispatch(setCompany(response.data.company));
                            sessionStorage.setItem('company', JSON.stringify(response.data.company));
                        } else {
                            dispatch(setCompany(null));
                            sessionStorage.setItem('company', 'null');
                        }
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des informations de l'utilisateur :", error);
                } finally {
                    setLoading(false);
                }
            };

            fetchUserInfo();
        } else if (companyStored) {
            dispatch(setCompany(companyStored === 'null' ? null : JSON.parse(companyStored)));
            setLoading(false);
        }
    }, [isAuthenticated, dispatch]);

    if (loading) {
        return null;
    }

    return <Outlet />;
};

export default CompanyCheckRoute;
