import React from 'react';

interface TextAreaProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ label, name, value, onChange, placeholder }) => {
    return (
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
                {label}
            </label>
            <textarea
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className="appearance-none block w-full border border-gray-300 rounded-lg px-4 py-2 leading-tight focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1"
                rows={4}
            />
        </div>
    );
};

export default TextArea;