import React, { useEffect } from 'react';

interface ModalProps {
    show: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ show, onClose, title, children }) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    if (!show) return null;

    return (
        <div
            className="fixed inset-0 bg-gray-900 bg-white/50 backdrop-blur flex justify-center items-center z-50"
            onClick={onClose} // Fermer lorsque l'utilisateur clique en dehors du modal
        >
            <div
                className="bg-white p-6 rounded shadow-lg w-1/3 max-h-full overflow-y-auto"
                onClick={(e) => e.stopPropagation()} // Empêcher la fermeture en cliquant dans le modal
                style={{ margin: '50px 0' }} // Ajouter de l'espace (margin) en haut et en bas
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl">{title}</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        &times;
                    </button>
                </div>
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Modal;
