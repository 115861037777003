import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import companyReducer from '../features/company/companySlice';
import customerReducer from '../features/customer/customerSlice';
import projectReducer from '../features/project/projectSlice';
import messagingReducer from '../features/messaging/messagingSlice';
import documentReducer from '../features/document/documentSlice';
import missionReducer from '../features/project/missionSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        company: companyReducer,
        customers: customerReducer,
        projects: projectReducer,
        missions: missionReducer,
        messaging: messagingReducer,
        documents: documentReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
