import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './store/store';
import { useTheme } from './hooks/useTheme';
import { fetchUserInfo, selectIsAuthenticated, setLoading } from './features/auth/authSlice';
import { setCompany, setLoading as setCompanyLoading } from './features/company/companySlice';
import { getCompanyInfo } from './api/companyService';
import { fetchProjects } from './features/project/projectSlice';
import { fetchCustomers } from './features/customer/customerSlice'; // Import du thunk pour récupérer les clients
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import DashboardPage from './views/DashboardPage';
import Customers from './views/CustomersPage';
import ProjectsPage from './views/ProjectsPage';
import MessagingPage from './views/MessagingPage';
import SettingsPage from './views/SettingsPage';
import CreateCompanyForm from './features/company/CreateCompanyForm';
import DocumentPage from './views/DocumentPage';
import PrivateRoute from './components/routes/PrivateRoute';
import CompanyCheckRoute from './components/routes/CompanyCheckRoute';
import NavbarTop from './components/NavbarTop';
import NavbarSide from './components/NavbarSide';
import './i18n/i18n';
import { useMercure } from './hooks/useMercure';

const App: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const userLoading = useSelector((state: RootState) => state.auth.loading);
    const companyLoading = useSelector((state: RootState) => state.company.loading);
    const projectsLoading = useSelector((state: RootState) => state.projects.loading); // Chargement des projets
    const customersLoading = useSelector((state: RootState) => state.customers.loading); // Chargement des clients
    const user = useSelector((state: RootState) => state.auth.user);

    const location = useLocation();
    const { theme, toggleTheme } = useTheme();
    const [actionButton, setActionButton] = useState<React.ReactNode>(null);

    useMercure();

    const hideNavbar = location.pathname === '/create-company';

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated && !user) {
                dispatch(setLoading(true)); // Commence le chargement de l'utilisateur
                try {
                    const userResponse = await dispatch(fetchUserInfo()).unwrap();

                    if (userResponse.company && userResponse.company.uuid) {
                        dispatch(setCompanyLoading(true)); // Commence le chargement de la société
                        const companyResponse = await getCompanyInfo(userResponse.company.uuid);
                        dispatch(setCompany(companyResponse.data));
                    } else {
                        dispatch(setCompany(null));
                    }

                    // Récupération des projets et clients après la récupération de l'utilisateur et de la société
                    await Promise.all([
                        dispatch(fetchProjects()),
                        dispatch(fetchCustomers()),
                    ]);
                } catch (error) {
                    console.error('Erreur lors de la récupération des informations utilisateur, société, projets ou clients', error);
                } finally {
                    dispatch(setLoading(false)); // Fin du chargement de l'utilisateur
                    dispatch(setCompanyLoading(false)); // Fin du chargement de la société
                }
            }
        };

        fetchData();
    }, [isAuthenticated, user, dispatch]);

    return (
        <div className="flex h-screen bg-theme-light overflow-hidden">
            {isAuthenticated && !hideNavbar && <NavbarSide theme={theme} toggleTheme={toggleTheme} />}
            <div className="flex-1 p-4 pl-0 flex flex-col overflow-hidden">
                <div className="bg-theme border-theme border-2 h-full rounded-lg flex flex-col overflow-hidden">
                    {isAuthenticated && !hideNavbar && <NavbarTop actionButton={actionButton} />}
                    <div className="flex-1 flex flex-col overflow-y-auto">
                        <Routes>
                            <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
                            <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />} />
                            <Route path="/signup" element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterPage />} />
                            <Route element={<PrivateRoute />}>
                                <Route path="/create-company" element={<CreateCompanyForm />} />
                                <Route element={<CompanyCheckRoute />}>
                                    <Route path="/dashboard" element={<DashboardPage />} />
                                    <Route path="/customers/*" element={<Customers setActionButton={setActionButton} />} />
                                    <Route path="/projects/*" element={<ProjectsPage setActionButton={setActionButton} />} />
                                    <Route path="/documents/*" element={<DocumentPage />} />
                                    <Route path="/messaging/*" element={<MessagingPage />} />
                                    <Route path="/settings" element={<SettingsPage />} />
                                </Route>
                            </Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AppWrapper: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;