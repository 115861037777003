import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface NavbarTopProps {
    actionButton?: React.ReactNode;
}

const NavbarTop: React.FC<NavbarTopProps> = ({ actionButton }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const pageTitles: { [key: string]: string } = {
        '/dashboard': t('pages.dashboard'),
        '/projects': t('pages.projects'),
        '/customers': t('pages.customers'),
        '/messaging': t('pages.messaging'),
        '/documents': t('pages.documents'),
        '/settings': t('pages.settings'),
    };

    return (
        <nav className="pl-6 p-4">
            <div className="flex items-center justify-between">
                <h1 className="text-theme text-xl">
                    {pageTitles[location.pathname] || t('pages.default')}
                </h1>
                {actionButton && <div>{actionButton}</div>} {/* Affiche le bouton si présent */}
            </div>
        </nav>
    );
};

export default NavbarTop;