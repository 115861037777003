import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, updateProject, createMission } from './projectSlice';
import { fetchCustomers, selectCustomers } from '../customer/customerSlice';
import { AppDispatch, RootState } from '../../store/store';
import { fetchMissionsByProjectId, selectMissionsByProjectId } from './missionSlice';

// Import des composants réutilisables
import InputText from '../../components/InputText';
import DateInput from '../../components/DateInput';
import TextArea from '../../components/TextArea';
import SearchableSelect from "../../components/SearchableSelect";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

interface Mission {
    uuid?: string;
    title: string;
    description: string;
    exceptedEndDate: string;
    amount: number;
}

interface FormData {
    title: string;
    description: string;
    customer: string;
}

interface ProjectFormProps {
    onSuccess: () => void;
    projectData?: {
        uuid: string;
        title: string;
        description: string;
        customer: string;
    } | null;
    isEditMode?: boolean;
    setFormData: (data: FormData) => void;
    formData: FormData;
    onNext?: () => void;
    isLoading: boolean;
}

const ProjectForm = forwardRef((props: ProjectFormProps, ref) => {
    const { onSuccess, projectData, isEditMode = false, setFormData, formData, onNext, isLoading } = props;
    const dispatch = useDispatch<AppDispatch>();
    const customers = useSelector(selectCustomers);

    const missionsFromRedux = useSelector((state: RootState) =>
        selectMissionsByProjectId(state, projectData?.uuid || '')
    );

    const [missions, setMissions] = useState<Mission[]>([
        { title: '', description: '', exceptedEndDate: '', amount: 0 }
    ]);

    // Nouvel état pour gérer l'expansion/collapse des missions
    const [expandedMissions, setExpandedMissions] = useState<boolean[]>([true]);

    const [message, setMessage] = useState<string | null>(null);

    useEffect(() => {
        if (projectData) {
            setFormData({
                title: projectData.title || '',
                description: projectData.description || '',
                customer: projectData.customer || ''
            });

            // Si le projet est en mode édition, on récupère les missions associées
            if (isEditMode && projectData.uuid) {
                dispatch(fetchMissionsByProjectId(projectData.uuid));
            }
        }

        if (customers.length === 0) {
            dispatch(fetchCustomers());
        }
    }, [dispatch, projectData, customers.length, setFormData, isEditMode]);

    // Utiliser les missions du store pour initialiser le state local
    useEffect(() => {
        if (missionsFromRedux.length > 0) {
            setMissions(missionsFromRedux);
            setExpandedMissions(new Array(missionsFromRedux.length).fill(true)); // Par défaut, toutes les missions seront expansées
        }
    }, [missionsFromRedux]);

    const handleProjectChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleMissionChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const newMissions = [...missions];

        if (name === 'amount') {
            newMissions[index] = { ...newMissions[index], [name]: parseInt(value, 10) || 0 };
        } else {
            newMissions[index] = { ...newMissions[index], [name]: value };
        }

        setMissions(newMissions);
    };

    const addMission = () => {
        setMissions([...missions, { title: '', description: '', exceptedEndDate: '', amount: 0 }]);
        setExpandedMissions([...expandedMissions, true]); // Ajouter la nouvelle mission comme "ouverte"
    };

    const removeMission = (index: number) => {
        const newMissions = missions.filter((_, i) => i !== index);
        const newExpandedMissions = expandedMissions.filter((_, i) => i !== index);
        setMissions(newMissions);
        setExpandedMissions(newExpandedMissions); // Supprimer l'état expanded correspondant
    };

    // Fonction pour toggler l'état "collapsible" de la mission
    const toggleMission = (index: number) => {
        const newExpandedMissions = [...expandedMissions];
        newExpandedMissions[index] = !newExpandedMissions[index]; // Inverser l'état
        setExpandedMissions(newExpandedMissions);
    };

    const handleSubmit = async () => {
        setMessage(null);

        try {
            let resultAction;
            if (isEditMode && projectData?.uuid) {
                const { title, description } = formData;
                const projectUpdateData = { title, description };

                resultAction = await dispatch(updateProject({
                    uuid: projectData.uuid,
                    projectData: projectUpdateData
                }));
            } else {
                resultAction = await dispatch(createProject(formData));

                if (createProject.fulfilled.match(resultAction)) {
                    const createdProject = resultAction.payload;
                    await Promise.all(
                        missions.map(mission =>
                            dispatch(createMission({ projectUuid: createdProject.uuid, missionData: mission }))
                        )
                    );
                }
            }

            if ((isEditMode && updateProject.fulfilled.match(resultAction)) || createProject.fulfilled.match(resultAction)) {
                setMessage(`Succès : Projet ${isEditMode ? 'modifié' : 'créé'} avec succès`);
                onSuccess();
            } else {
                setMessage(`Erreur : ${resultAction.payload ?? `Échec de la ${isEditMode ? 'modification' : 'création'} du projet`}`);
            }
        } catch (error) {
            setMessage(`Erreur lors de la ${isEditMode ? 'modification' : 'création'} du projet : ${error}`);
        }
    };

    useImperativeHandle(ref, () => ({
        submitForm: handleSubmit,
    }));

    return (
        <div className="flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <h2  className="text-xl font-semibold">Ajouter un projet</h2>
                <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                    <div className="rounded-md">
                        <InputText
                            label="Titre du projet"
                            name="title"
                            value={formData.title}
                            onChange={handleProjectChange}
                            placeholder="Entrez le titre du projet"
                        />
                        <TextArea
                            label="Description du projet"
                            name="description"
                            value={formData.description}
                            onChange={handleProjectChange}
                            placeholder="Entrez la description du projet"
                        />
                        <SearchableSelect
                            label="Sélectionner un client"
                            name="customer"
                            value={formData.customer}
                            options={customers.map((customer) => ({
                                value: customer.uuid,
                                label: `${customer.companyName} - ${customer.firstName} ${customer.lastName}`
                            }))}
                            onChange={(value) => setFormData({ ...formData, customer: value })}
                            onButtonClick={onNext || (() => {})}
                            buttonLabel="Nouveau client"
                        />
                    </div>
                    <div className="space-y-4">
                        {missions.map((mission, index) => (
                            <div key={index} className="rounded-md border border-gray-200 p-4">
                                <div
                                    className="flex justify-between items-center cursor-pointer"
                                    onClick={() => toggleMission(index)}
                                >
                                    <span>{mission.title || `Mission ${index + 1}`}</span>
                                    <span>{expandedMissions[index] ? <FiChevronUp /> : <FiChevronDown />}</span> {/* Utilisation d'une flèche */}
                                </div>
                                {expandedMissions[index] && (
                                    <div className="mt-4">
                                        <InputText
                                            label="Titre de la mission"
                                            name="title"
                                            value={mission.title}
                                            onChange={(e) => handleMissionChange(index, e)}
                                            placeholder="Entrez le titre de la mission"
                                        />
                                        <TextArea
                                            label="Description de la mission"
                                            name="description"
                                            value={mission.description}
                                            onChange={(e) => handleMissionChange(index, e)}
                                            placeholder="Entrez la description de la mission"
                                        />
                                        <div className="flex gap-4">
                                            <div className="w-1/2">
                                                <DateInput
                                                    label="Date de fin prévue"
                                                    name="exceptedEndDate"
                                                    value={mission.exceptedEndDate}
                                                    onChange={(e) => handleMissionChange(index, e)}
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <InputText
                                                    label="Montant"
                                                    name="amount"
                                                    value={mission.amount.toString()}
                                                    onChange={(e) => handleMissionChange(index, e)}
                                                    placeholder="Entrez le montant"
                                                />
                                            </div>
                                        </div>
                                        <button type="button" onClick={() => removeMission(index)} className="text-red-500 mt-2">
                                            Supprimer
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))}
                        <button type="button" onClick={addMission} className="text-indigo-600">Ajouter une mission</button>
                    </div>
                    {message && (
                        <div className="text-center mt-4 text-red-500">
                            {message}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
});

export default ProjectForm;