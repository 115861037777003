import axiosInstance from './apiService';
import { AxiosError } from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';

// Récupère la liste des topics en utilisant le token utilisateur
export const discoverMercure = async () => {
    try {
        const response = await axiosInstance.get('/v1/mercure/discover');
        const topics = response.data.map((item: any) => item.topic); // Extrait les topics

        // Le token est dans le cookie HttpOnly, vous n'avez pas besoin de le manipuler ici.

        return { topics };
    } catch (error) {
        if (error instanceof AxiosError) {
            console.error('Erreur lors de la découverte de Mercure:', error.response?.data || error.message);
        } else {
            console.error('Erreur lors de la découverte de Mercure:', error);
        }
        throw error;
    }
};

// Connecte au hub Mercure pour écouter les événements en temps réel
export const connectToMercure = (topics: string[], onMessage: (event: any) => void) => {
    const baseURL = axiosInstance.defaults.baseURL;
    if (!baseURL) {
        console.error('Base URL is not set in axiosInstance.');
        throw new Error('Base URL is not set in axiosInstance.');
    }

    // Créez l'URL pour la connexion à Mercure
    const url = new URL('/.well-known/mercure', baseURL);
    topics.forEach((topic) => {
        url.searchParams.append('topic', topic);
    });

    console.log('Tentative de connexion à Mercure avec l\'URL:', url.toString());

    // Utilisation de EventSourcePolyfill pour la connexion (ou EventSource natif si le polyfill n'est pas nécessaire)
    const eventSource = new EventSourcePolyfill(url.toString(), { withCredentials: true });

    console.log('EventSource créé pour:', url.toString());

    eventSource.onopen = () => {
        console.log('Connexion à Mercure ouverte');
    };

    // Utilisation de addEventListener pour récupérer les événements spécifiques
    eventSource.addEventListener('messages::create', (event: MessageEvent) => {
        console.log('Événement "messages::create" reçu:', event.data);

        if (event.data && event.data !== ":") {  // Assurez-vous que event.data existe et n'est pas simplement ":"
            try {
                // Log des données brutes avant le parsing
                console.log('Données brutes reçues:', event.data);

                // Parse the data
                const parsedData = JSON.parse(event.data);

                // Log le body des données parsées
                if (parsedData.body) {
                    console.log('Body du message reçu:', parsedData.body);
                } else {
                    console.log('Le body est manquant ou undefined dans le message reçu:', parsedData);
                }

                // Passer les données parsées à la fonction callback
                onMessage(parsedData);
            } catch (error) {
                console.error('Erreur lors du parsing de la donnée:', error);
            }
        } else {
            console.error('Aucune donnée ou donnée invalide reçue dans l\'événement.');
        }
    });

    eventSource.onerror = (error: Event) => {
        console.error('Erreur de connexion à Mercure:', error);
    };

    console.log('EventSource configuré et en attente de messages.');

    return eventSource;
};