import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getDocument, getUserDocuments, uploadDocument, deleteDocument } from '../../api/documentService';  // Assurez-vous que updateDocument est bien importé

// État initial
const initialState = {
    documents: [] as any[],  // Initialize documents as an empty array
    selectedDocument: null as any | null,
    loading: false,
    error: null as string | null,
};

// Thunks pour les opérations asynchrones
export const fetchDocuments = createAsyncThunk(
    'documents/fetchDocuments',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUserDocuments();
            console.log(response)
            // Vérifiez si la réponse est un tableau directement et retournez-le
            return Array.isArray(response.items) ? response.items : [];
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const fetchDocument = createAsyncThunk(
    'documents/fetchDocument',
    async (uuid: string, { rejectWithValue }) => {
        try {
            const response = await getDocument(uuid);
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const removeDocument = createAsyncThunk(
    'documents/deleteDocument',
    async (uuid: string, { rejectWithValue }) => {
        try {
            await deleteDocument(uuid);
            return uuid;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const addDocument = createAsyncThunk(
    'documents/uploadDocument',
    async ({ documentData, projectUuid }: { documentData: any, projectUuid: string }, { rejectWithValue }) => {
        try {
            const response = await uploadDocument(documentData, projectUuid);
            return response.data;  // Assurez-vous de retourner la bonne réponse
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Slice pour les documents
const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        clearSelectedDocument: (state) => {
            state.selectedDocument = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocuments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDocuments.fulfilled, (state, action) => {
                state.documents = action.payload;  // Utilise directement le tableau retourné
                state.loading = false;
            })
            .addCase(fetchDocuments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(fetchDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDocument.fulfilled, (state, action) => {
                state.selectedDocument = action.payload;
                state.loading = false;
            })
            .addCase(fetchDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(removeDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeDocument.fulfilled, (state, action) => {
                state.documents = state.documents.filter(
                    (document: any) => document.uuid !== action.payload
                );
                state.loading = false;
            })
            .addCase(removeDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(addDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addDocument.fulfilled, (state, action) => {
                state.documents.push(action.payload);
                state.loading = false;
            })
            .addCase(addDocument.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
    },
});

// Sélecteurs pour accéder à l'état
export const selectDocuments = (state: any) => state.documents.documents;
export const selectDocumentsLoading = (state: any) => state.documents.loading;
export const selectDocumentsError = (state: any) => state.documents.error;
export const selectDocumentById = (state: any, uuid: string) =>
    state.documents.documents.find((document: any) => document.uuid === uuid);
export const selectSelectedDocument = (state: any) => state.documents.selectedDocument;

// Export des actions et du reducer pour l'intégrer dans le store
export const { clearSelectedDocument } = documentSlice.actions;
export default documentSlice.reducer;