import React, { useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import DocumentList from '../features/document/DocumentList';
import DocumentForm from '../features/document/DocumentForm';
import DocumentDetail from '../features/document/DocumentDetail';
import Modal from '../components/Modal';

const DocumentPage: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const projectUuid = 'votre_project_uuid'; // Remplacez par l'UUID réel du projet

    const handleCreateDocument = () => {
        setSelectedDocument(null);
        setShowModal(true);
    };

    const handleEditDocument = (document: any) => {
        setSelectedDocument(document);
        setShowModal(true);
    };

    const handleViewDetails = (uuid: string) => {
        navigate(`/documents/${uuid}`);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const isDocumentListPage = location.pathname === '/documents';

    return (
        <div className="p-4 h-full">
            <div className="flex justify-between items-center mb-4">
                {isDocumentListPage && (
                    <button
                        className="px-4 py-2 bg-indigo-600 text-white rounded"
                        onClick={handleCreateDocument}
                    >
                        Nouveau Document
                    </button>
                )}
            </div>

            <Routes>
                <Route
                    path="/"
                    element={<DocumentList onEditDocument={handleEditDocument} onViewDetails={handleViewDetails} />}
                />
                <Route
                    path=":uuid"
                    element={<DocumentDetail onEditDocument={handleEditDocument} />}
                />
            </Routes>

            <Modal
                show={showModal}
                onClose={closeModal}
                title={selectedDocument ? 'Modifier le document' : 'Créer un document'}
            >
                <DocumentForm
                    onSuccess={closeModal}
                    documentData={selectedDocument}
                    isEditMode={!!selectedDocument}
                    projectUuid={projectUuid}  // Passez l'UUID du projet ici
                />
            </Modal>
        </div>
    );
};

export default DocumentPage;