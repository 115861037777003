import React, { useState } from 'react';

interface InputEmailProps {
    label: string;
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    required?: boolean;
}

const InputEmail: React.FC<InputEmailProps> = ({ label, name, value, onChange, placeholder, required = false }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const validateEmail = (email: string) => {
        // Regex pour valider le format d'email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (!validateEmail(value)) {
            setErrorMessage('Format d\'email invalide');
        } else {
            setErrorMessage(null);
        }

        onChange(e); // Continue de propager le changement à la fonction onChange parent
    };

    return (
        <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2">
                {label}{required && '*'}
            </label>
            <input
                type="email"
                name={name}
                value={value}
                onChange={handleEmailChange}
                placeholder={placeholder}
                className={`appearance-none block w-full border rounded-lg px-4 py-2 leading-tight focus:outline-none ${errorMessage ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-blue-500'} focus:ring-1`}
                required={required}
            />
            {errorMessage && (
                <p className="text-red-500 text-xs mt-2">{errorMessage}</p>
            )}
        </div>
    );
};

export default InputEmail;