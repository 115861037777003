import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { createCompany } from '../../api/companyService';
import { setCompany } from './companySlice';

const CreateCompanyForm: React.FC = () => {
    console.log('CreateCompanyForm is trying to render');
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        legalName: '',
        commercialName: '',
        siret: '',
        vatNumber: '',
        mainColor: '',
        secondaryColor: '',
        logo: ''
    });
    const [message, setMessage] = useState('');
    const company = useSelector((state: RootState) => state.company.company);
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Ajout d'un useEffect pour afficher les informations utilisateur
    useEffect(() => {

        if (company) {
            navigate('/dashboard');
        }
    }, [company, user, navigate]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await createCompany(formData);
            if (response.status === 200 || response.status === 201) {
                dispatch(setCompany(response.data));
                setMessage('Compagnie créée avec succès !');
                navigate('/dashboard');
            } else {
                setMessage('Erreur lors de la création de la compagnie');
            }
        } catch (error) {
            console.error('Erreur lors de la création de la compagnie:', error);
            setMessage('Erreur lors de la création de la compagnie');
        }
    };

    if (company) {
        // Si l'entreprise existe, redirige vers le tableau de bord
        return null;
    }

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                        Félicitations, vous venez de créer votre compte !
                    </h2>
                    <p className="text-center">
                        Il reste quelques étapes supplémentaires pour finaliser votre compte...
                    </p>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    {step === 1 && (
                        <>
                            <div className="rounded-md shadow-sm">
                                <div>
                                    <input
                                        name="legalName"
                                        type="text"
                                        required
                                        placeholder="Nom légal"
                                        value={formData.legalName}
                                        onChange={handleChange}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                                </div>
                                <div>
                                    <input
                                        name="commercialName"
                                        type="text"
                                        required
                                        placeholder="Nom commercial"
                                        value={formData.commercialName}
                                        onChange={handleChange}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                                </div>
                                <div>
                                    <input
                                        name="siret"
                                        type="text"
                                        required
                                        placeholder="SIRET"
                                        value={formData.siret}
                                        onChange={handleChange}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                                </div>
                            </div>
                            <div className={"flex items-center justify-between mt-6"}>
                                <button onClick={() => setStep(2)} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Suivant
                                </button>
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <div className="rounded-md shadow-sm">
                                <div>
                                    <label>
                                        Éligible à la TVA ?
                                        <input
                                            type="checkbox"
                                            name="vatEligible"
                                            onChange={() => setFormData({
                                                ...formData,
                                                vatNumber: formData.vatNumber ? '' : formData.vatNumber
                                            })}/>
                                    </label>
                                    {formData.vatNumber !== '' && (
                                        <input
                                            name="vatNumber"
                                            type="text"
                                            placeholder="Numéro de TVA"
                                            value={formData.vatNumber}
                                            onChange={handleChange}
                                            className="input-class"/>
                                    )}
                                </div>
                                <div>
                                    <input
                                        name="mainColor"
                                        type="text"
                                        placeholder="Couleur principale (hex)"
                                        value={formData.mainColor}
                                        onChange={handleChange}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                                </div>
                                <div>
                                    <input
                                        name="secondaryColor"
                                        type="text"
                                        placeholder="Couleur secondaire (hex)"
                                        value={formData.secondaryColor}
                                        onChange={handleChange}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 border-b-0 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                                </div>
                                <div>
                                    <input
                                        name="logo"
                                        type="text"
                                        placeholder="Logo (UUID)"
                                        value={formData.logo}
                                        onChange={handleChange}
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                                </div>
                            </div>
                            <div className={"text-center mt-4"}>
                                <button onClick={() => setStep(1)} className="text-indigo-600 hover:text-indigo-500">
                                    Précédent
                                </button>
                            </div>
                            <div className={"flex items-center justify-between mt-6"}>
                                <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    Soumettre
                                </button>
                            </div>
                        </>
                    )}
                    {message && <div className="text-center mt-4 text-red-500">{message}</div>}
                </form>
            </div>
        </div>
    );
};

export default CreateCompanyForm;
