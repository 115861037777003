import { useEffect, useState } from 'react';
import { discoverMercure, connectToMercure } from '../api/mercureService';
import { useDispatch } from 'react-redux';
import { receiveMercureMessage, addUnreadMessage } from '../features/messaging/messagingSlice';

export const useMercure = (threadUuid?: string) => {
    const [topics, setTopics] = useState<string[] | null>(null);
    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const initializeMercure = async () => {
            try {
                const { topics } = await discoverMercure();
                console.log('Topics découverts:', topics);
                setTopics(topics);

                const eventSource = connectToMercure(topics, (parsedData) => {
                    console.log('Message reçu via Mercure et traité:', parsedData);

                    // Traitement du message reçu ici, par exemple:
                    if (threadUuid && parsedData.thread.uuid === threadUuid) {
                        dispatch(receiveMercureMessage({
                            threadId: parsedData.thread.uuid,
                            message: {
                                uuid: parsedData.uuid,
                                content: parsedData.body,
                                sender: { firstName: parsedData.sender.firstName, lastName: parsedData.sender.lastName },
                                createdAt: parsedData.createdAt,
                                status: 'sent',
                                threadId: parsedData.thread.uuid,
                                nonce: parsedData.nonce,
                            }
                        }));
                    } else {
                        dispatch(addUnreadMessage(parsedData.thread.uuid));
                    }
                });

                setInitialized(true);
                return () => {
                    eventSource.close();
                };
            } catch (error) {
                console.error('Erreur lors de la connexion à Mercure:', error);
            }
        };

        if (!initialized) {
            initializeMercure();
        }
    }, [dispatch, initialized, threadUuid]);

    return { topics, initialized };
};