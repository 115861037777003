import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';

const resources = {
    en: {
        translation: enTranslation,
    },
    fr: {
        translation: frTranslation,
    },
};

// Récupère la langue enregistrée dans le localStorage ou utilise 'en' par défaut
const savedLanguage = localStorage.getItem('language') || 'fr';

i18n
    .use(initReactI18next) // passe `i18n` à `react-i18next`
    .init({
        resources,
        lng: savedLanguage,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false, // réagit déjà par défaut
        },
    });

export default i18n;