import qs from "qs";
import axiosInstance from './apiService';

// Fonction pour récupérer tous les clients
export const getCustomers = () => {
    return axiosInstance.get('/v1/customers')
        .then(response => response.data)
        .catch(error => {
            console.error('Erreur lors de la récupération des clients:', error);
            throw error;
        });
};

// Fonction pour créer un client
export const createCustomer = (customerData: any) => {
    const data = qs.stringify(customerData);
    return axiosInstance.post('/v1/customers', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la création du client:', error);
        throw error;
    });
};

// Fonction pour mettre à jour un client
export const updateCustomer = (uuid: string, customerData: any) => {
    const data = qs.stringify(customerData);
    return axiosInstance.patch(`/v1/customers/${uuid}`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Erreur lors de la mise à jour du client:', error);
        throw error;
    });
};

// Fonction pour supprimer un client
export const deleteCustomer = (uuid: string) => {
    return axiosInstance.delete(`/v1/customers/${uuid}`)
        .then(response => {
            return response;
        }).catch(error => {
            console.error('Erreur lors de la suppression du client:', error);
            throw error;
        });
};

// Fonction pour récupérer un client spécifique par son UUID
export const getCustomerById = (uuid: string) => {
    return axiosInstance.get(`/v1/customers/${uuid}`)
        .then(response => response.data)
        .catch(error => {
            console.error(`Erreur lors de la récupération du client avec l'UUID ${uuid}:`, error);
            throw error;
        });
};