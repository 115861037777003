import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchDocument, selectDocumentById, selectDocumentsLoading } from './documentSlice';
import { AppDispatch } from '../../store/store';

interface DocumentDetailProps {
    onEditDocument: (document: any) => void;
}

const DocumentDetail: React.FC<DocumentDetailProps> = ({ onEditDocument }) => {
    const { uuid } = useParams<{ uuid: string }>();
    const dispatch = useDispatch<AppDispatch>();

    // Sélectionner le document par UUID
    const document = useSelector((state: any) => uuid ? selectDocumentById(state, uuid) : null);
    const loading = useSelector(selectDocumentsLoading);

    // Fetch le document si l'UUID est disponible
    useEffect(() => {
        if (uuid) {
            dispatch(fetchDocument(uuid));
        }
    }, [dispatch, uuid]);

    // Affichage du chargement pendant la récupération des données
    if (loading) {
        return <p>Chargement...</p>;
    }

    // Affichage du message d'erreur si le document est introuvable après le chargement
    if (!document && !loading) {
        return <p>Document non trouvé.</p>;
    }

    return (
        <div className="p-4 bg-white rounded shadow">
            <h2 className="text-xl font-bold">{document.displayText || 'Titre non renseigné'}</h2>
            <p>Nom du fichier : {document.name}</p>
            <p>Taille : {document.size} octets</p>
            <p>MIME Type : {document.mimeType}</p>
            {/* Autres détails du document ici */}
            <button
                className="px-4 py-2 mt-4 bg-blue-500 text-white rounded"
                onClick={() => onEditDocument(document)}
            >
                Modifier
            </button>
        </div>
    );
};

export default DocumentDetail;