import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
    createCustomer as createCustomerAPI,
    deleteCustomer as apiDeleteCustomer,
    getCustomers,
    getCustomerById as apiGetCustomerById,
    updateCustomer as apiUpdateCustomer
} from '../../api/customerService';

interface Customer {
    uuid: string;
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface NewCustomer {
    companyName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

interface CustomersState {
    customers: Customer[];
    loading: boolean;
    error: string | null;
}

const initialState: CustomersState = {
    customers: [],
    loading: false,
    error: null,
};

// Helper function to handle errors
const handleRejected = <T>(state: CustomersState, action: PayloadAction<unknown, string, T, SerializedError>) => {
    state.loading = false;
    state.error = action.error.message ?? 'Une erreur est survenue';
};

// Thunks
export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async (_, { rejectWithValue }) => {
    try {
        const { items } = await getCustomers();
        return items;
    } catch {
        return rejectWithValue('Échec de la récupération des clients');
    }
});

export const fetchCustomerById = createAsyncThunk('customers/fetchCustomerById', async (uuid: string, { rejectWithValue }) => {
    try {
        return await apiGetCustomerById(uuid);
    } catch {
        return rejectWithValue('Échec de la récupération du client');
    }
});

export const createCustomer = createAsyncThunk('customers/createCustomer', async (customerData: NewCustomer, { rejectWithValue }) => {
    try {
        return await createCustomerAPI(customerData);
    } catch {
        return rejectWithValue('Erreur lors de la création du client');
    }
});

export const updateCustomer = createAsyncThunk(
    'customers/updateCustomer',
    async ({ uuid, customerData }: { uuid: string; customerData: Omit<Customer, 'uuid'> }) => {
        return await apiUpdateCustomer(uuid, customerData);
    }
);

export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (uuid: string, { rejectWithValue }) => {
    try {
        await apiDeleteCustomer(uuid);
        return uuid;
    } catch {
        return rejectWithValue('Erreur lors de la suppression du client');
    }
});

const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomers.fulfilled, (state, action: PayloadAction<Customer[]>) => {
                state.customers = action.payload;
                state.loading = false;
            })
            .addCase(fetchCustomers.rejected, handleRejected)
            .addCase(fetchCustomerById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCustomerById.fulfilled, (state, action: PayloadAction<Customer>) => {
                const index = state.customers.findIndex(customer => customer.uuid === action.payload.uuid);
                if (index >= 0) {
                    state.customers[index] = action.payload;
                } else {
                    state.customers.push(action.payload);
                }
                state.loading = false;
            })
            .addCase(fetchCustomerById.rejected, handleRejected)
            .addCase(createCustomer.fulfilled, (state, action: PayloadAction<Customer>) => {
                state.customers.push(action.payload);
            })
            .addCase(createCustomer.rejected, handleRejected)
            .addCase(updateCustomer.fulfilled, (state, action: PayloadAction<Customer>) => {
                state.customers = state.customers.map(customer =>
                    customer.uuid === action.payload.uuid ? action.payload : customer
                );
            })
            .addCase(deleteCustomer.fulfilled, (state, action: PayloadAction<string>) => {
                state.customers = state.customers.filter(customer => customer.uuid !== action.payload);
            });
    }
});

export const selectCustomers = (state: RootState) => state.customers.customers;
export const selectCustomersLoading = (state: RootState) => state.customers.loading;
export const selectCustomersError = (state: RootState) => state.customers.error;

export const selectCustomerById = (state: RootState, uuid: string) =>
    state.customers.customers.find(customer => customer.uuid === uuid);

export default customerSlice.reducer;