import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
    fetchMessages,
    fetchThreadDetail,
    markAsRead,
    removeMessage,
    sendNewMessage,
    updateMessageStatus,
    addTempMessage
} from './messagingSlice';
import { fetchUserInfo, selectUser } from '../auth/authSlice';
import { fetchProjectById, selectProjectById } from '../project/projectSlice';
import { useMercure } from '../../hooks/useMercure';
import ThreadHeader from './components/ThreadHeader';
import ThreadMessagesList from './components/ThreadMessagesList';
import ThreadMessageInput from './components/ThreadMessageInput';
import DropOverlay from './components/DropOverlay'; // Importer le composant DropOverlay
import { uploadDocument } from '../../api/documentService';

const ThreadContainer: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const [file, setFile] = useState<File | null>(null); // Gérer le fichier sélectionné
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const user = useSelector(selectUser);
    const thread = useSelector((state: RootState) => state.messaging.threads.find((t) => t.uuid === uuid));
    const messages = useSelector((state: RootState) => uuid ? state.messaging.messages[uuid] || [] : []);
    const loadingState = useSelector((state: RootState) => state.messaging.loading || state.auth.loading);
    const project = useSelector((state: RootState) => thread?.project?.uuid ? selectProjectById(state, thread.project.uuid) : null);

    useMercure(uuid);

    useEffect(() => {
        if (uuid) {
            dispatch(fetchThreadDetail(uuid));
            dispatch(fetchMessages(uuid));
            dispatch(markAsRead({ threadId: uuid }));
        }
        dispatch(fetchUserInfo());

        if (thread?.project?.uuid) {
            dispatch(fetchProjectById(thread.project.uuid));
        }
    }, [dispatch, uuid, thread?.project?.uuid]);

    // Fonction pour gérer l'envoi d'un message
    const handleSendMessage = async (message: string) => {
        if (!uuid) return;

        const nonce = generateNonce();

        const tempMessage = {
            content: message.trim(),
            sender: user,
            createdAt: new Date().toISOString(),
            status: 'pending',
            nonce,
        };

        // Ajouter le message temporaire dans l'interface
        dispatch(addTempMessage({ threadId: uuid, message: tempMessage }));

        try {
            const response = await dispatch(sendNewMessage({ threadId: uuid, content: message, nonce })).unwrap();
            dispatch(updateMessageStatus({
                threadId: uuid,
                nonce: response.message.nonce,
                status: 'sent',
                updatedMessage: { ...response.message }
            }));
        } catch (error) {
            dispatch(updateMessageStatus({ threadId: uuid, nonce, status: 'failed' }));
        }
    };

    // Fonction pour gérer l'envoi d'un document uniquement
    const handleSendDocument = async (documentUuid: string) => {
        if (!uuid) return;

        const tempMessage = {
            content: '',
            sender: user,
            createdAt: new Date().toISOString(),
            status: 'pending',
            nonce: generateNonce(),
            isDocument: true,
            documents: [documentUuid],
        };

        dispatch(addTempMessage({ threadId: uuid, message: tempMessage }));
    };

    const handleDeleteMessage = (messageId: string) => {
        if (uuid && messageId) {
            dispatch(removeMessage({ threadId: uuid, messageId }));
        }
    };

    if (!uuid) {
        return <div>Thread ID non disponible</div>;
    }

    return (
        <div className="relative flex flex-col h-full">
            {/* Ajouter DropOverlay pour détecter le glisser-déposer sur toute la page */}
            <DropOverlay setFile={setFile} />

            <ThreadHeader loading={loadingState} thread={thread} project={project} />
            <ThreadMessagesList messages={messages} user={user} handleDeleteMessage={handleDeleteMessage} threadId={uuid} />
            <ThreadMessageInput
                onSendMessage={handleSendMessage}
                onSendDocument={handleSendDocument}
                file={file}
                textareaRef={textareaRef}
                fileInputRef={fileInputRef}
                setFile={setFile}
                projectUuid={project?.uuid || ''}
            />
        </div>
    );
};

export default ThreadContainer;

// Fonction pour générer un nonce unique
const generateNonce = () => {
    return (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, 20);
};