import React, { useState, useEffect } from 'react';
import { FiArrowUp, FiX, FiFile, FiImage } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import ThreadFileUploader from './ThreadFileUploader';
import { addDocument } from '../../document/documentSlice';

interface ThreadMessageInputProps {
    onSendMessage: (message: string) => void;
    onSendDocument: (documentUuid: string) => void;
    file: File | null;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
    fileInputRef: React.RefObject<HTMLInputElement>;
    setFile: (file: File | null) => void;
    projectUuid: string; // Prop pour indiquer le projet lié
}

const ThreadMessageInput: React.FC<ThreadMessageInputProps> = ({
                                                                   onSendMessage,
                                                                   onSendDocument,
                                                                   file,
                                                                   textareaRef,
                                                                   fileInputRef,
                                                                   setFile,
                                                                   projectUuid,
                                                               }) => {
    const [newMessage, setNewMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        adjustTextareaHeight();
    }, []);

    const adjustTextareaHeight = () => {
        const lineHeight = 20; // Hauteur d'une ligne
        const maxHeight = 120;

        if (textareaRef.current) {
            textareaRef.current.style.height = `${lineHeight}px`;
            const scrollHeight = textareaRef.current.scrollHeight;

            if (scrollHeight > lineHeight) {
                textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
                textareaRef.current.style.overflowY = scrollHeight > maxHeight ? 'auto' : 'hidden';
            } else {
                textareaRef.current.style.height = `${lineHeight}px`;
                textareaRef.current.style.overflowY = 'hidden';
            }
        }
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(e.target.value);
        adjustTextareaHeight();
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            await handleSubmit(); // Envoie du message et/ou document si Enter est appuyé
        }
    };

    const handleSubmit = async (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        setUploading(true);

        try {
            if (file && !newMessage.trim()) {
                const documentData = new FormData();
                documentData.append('file', file);
                const response = await dispatch(addDocument({ documentData, projectUuid })).unwrap();
                onSendDocument(response.uuid); // Envoyer seulement le document
                setFile(null); // Réinitialiser le fichier après l'upload
            }

            if (!file && newMessage.trim()) {
                onSendMessage(newMessage.trim());
                setNewMessage(''); // Réinitialiser le message
            }

            if (file && newMessage.trim()) {
                const documentData = new FormData();
                documentData.append('file', file);
                const response = await dispatch(addDocument({ documentData, projectUuid })).unwrap();

                onSendMessage(newMessage.trim());
                onSendDocument(response.uuid);

                setFile(null); // Réinitialiser le fichier après l'upload
                setNewMessage(''); // Réinitialiser le message
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message ou du document : ', error);
        } finally {
            setUploading(false);
            if (textareaRef.current) {
                textareaRef.current.style.height = '40px'; // Réinitialiser la hauteur après l'envoi
            }
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Réinitialiser l'input file
        }
    };

    const isButtonDisabled = !newMessage.trim() && !file; // Désactiver le bouton si ni message ni fichier n'est présent

    return (
        <form
            onSubmit={handleSubmit}
            className="p-4 flex flex-col space-y-2 w-full"
            style={{
                flexShrink: 0,
                position: 'sticky',
                bottom: 0,
                zIndex: 10,
            }}
        >
            {/* Prévisualisation du document au-dessus du champ de saisie avec une largeur limitée */}
            {file && (
                <div className="relative inline-flex items-center p-2 mb-2 bg-gray-100 border rounded max-w-max">
                    <div className="flex items-center justify-center w-12 h-12 mr-2 bg-gray-200 rounded">
                        {file.type.startsWith('image/') ? (
                            <FiImage className="text-2xl text-theme"/>
                        ) : (
                            <FiFile className="text-2xl text-theme"/>
                        )}
                    </div>
                    <span className="text-sm truncate w-40">{file.name}</span>
                    <button
                        type="button"
                        className="ml-2 p-1 bg-red-500 rounded-full text-white"
                        onClick={handleRemoveFile}
                    >
                        <FiX/>
                    </button>
                </div>
            )}

            <div className="flex items-end w-full bg-theme-extra-light rounded-[26px] p-1">
                {/* Uploader le fichier */}
                <ThreadFileUploader
                    file={file}
                    setFile={setFile}
                    fileInputRef={fileInputRef}
                    projectUuid={projectUuid}
                />

                {/* Champ textarea pour le message */}
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={handleTextareaChange}
                    onKeyDown={handleKeyDown}
                    className="flex-1 bg-theme-extra-light text-theme resize-none border-none focus:ring-0 focus:border-none rounded-none"
                    placeholder="Écrire un message..."
                    style={{
                        padding: '8px',
                        boxSizing: 'border-box',
                        height: '40px',
                        maxHeight: '120px',
                        minHeight: '40px',
                        overflowY: 'hidden',
                        outline: 'none',
                    }}
                />

                {/* Bouton d'envoi */}
                <button
                    type="submit"
                    className={`w-10 h-10 flex items-center justify-center rounded-full cursor-pointer bg-primaryColor ${
                        uploading || isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={uploading || isButtonDisabled} // Désactiver le bouton si pas de message ou document
                >
                    <FiArrowUp className="text-white text-xl" />
                </button>
            </div>
        </form>
    );
};

export default ThreadMessageInput;