import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { getMissionsByProject, updateMissionStatus } from '../../api/projectService';

interface Mission {
    uuid: string;
    title: string;
    description: string;
    exceptedEndDate: string;
    amount: number;
    status: string;
}

interface MissionsState {
    missions: Record<string, Mission[]>;
    loading: boolean;
    error: string | null;
}

const initialState: MissionsState = {
    missions: {},
    loading: false,
    error: null,
};

export const fetchMissionsByProjectId = createAsyncThunk(
    'missions/fetchMissionsByProjectId',
    async (projectUuid: string, { rejectWithValue }) => {
        try {
            const response = await getMissionsByProject(projectUuid);
            return { projectUuid, missions: response.items };
        } catch {
            return rejectWithValue('Erreur lors de la récupération des missions');
        }
    }
);

// Thunk pour mettre à jour le statut d'une mission
export const updateMissionStatusThunk = createAsyncThunk(
    'missions/updateMissionStatusThunk',
    async ({ projectUuid, missionUuid, status }: { projectUuid: string; missionUuid: string; status: string }, { rejectWithValue }) => {
        try {
            await updateMissionStatus(projectUuid, missionUuid, status); // Utilisation de l'API
            return { projectUuid, missionUuid, status };
        } catch {
            return rejectWithValue('Erreur lors de la mise à jour du statut de la mission');
        }
    }
);

const missionSlice = createSlice({
    name: 'missions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMissionsByProjectId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMissionsByProjectId.fulfilled, (state, action: PayloadAction<{ projectUuid: string; missions: Mission[] }>) => {
                state.missions[action.payload.projectUuid] = action.payload.missions;
                state.loading = false;
            })
            .addCase(fetchMissionsByProjectId.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
                state.loading = false;
                state.error = action.error.message ?? 'Une erreur est survenue';
            })
            // Gestion de la mise à jour du statut des missions
            .addCase(updateMissionStatusThunk.fulfilled, (state, action: PayloadAction<{ projectUuid: string; missionUuid: string; status: string }>) => {
                const { projectUuid, missionUuid, status } = action.payload;
                const missions = state.missions[projectUuid];
                const missionIndex = missions.findIndex(mission => mission.uuid === missionUuid);
                if (missionIndex !== -1) {
                    state.missions[projectUuid][missionIndex].status = status; // Mise à jour du statut
                }
            })
            .addCase(updateMissionStatusThunk.rejected, (state, action) => {
                state.error = action.error.message ?? 'Erreur lors de la mise à jour du statut';
            });
    }
});

export const selectMissionsByProjectId = (state: RootState, projectUuid: string) =>
    state.missions.missions[projectUuid] || [];

export default missionSlice.reducer;