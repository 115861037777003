import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectById, selectProjectById, deleteProject, selectProjectsLoading } from './projectSlice';
import { fetchCustomerById, selectCustomerById } from '../customer/customerSlice';
import Skeleton from '../../components/Skeleton';
import { AppDispatch, RootState } from '../../store/store';
import {fetchMissionsByProjectId, selectMissionsByProjectId, updateMissionStatusThunk} from './missionSlice';
import {
    FiFile,
    FiImage,
    FiChevronDown,
    FiChevronUp,
    FiClock,
    FiCheck,
    FiRotateCw,
    FiInfo,
    FiPhone, FiMail
} from 'react-icons/fi';
import DragScrollContainer from "../../components/DragScrollContainer";
import { formatDate } from '../../utils/dateUtils';
import { formatFileSize } from '../../utils/fileUtils';
import {formatPrice} from "../../utils/formatPrice";

interface ProjectDetailProps {
    onEditProject: (project: any) => void;
}

interface Customer {
    uuid: string;
    companyName: string;
    firstName: string;
    lastName: string;
}

// Composant Timeline pour afficher les missions avec TailwindCSS
const Timeline: React.FC<{ missions: any[], projectUuid: string }> = ({ missions, projectUuid }) => {
    const dispatch = useDispatch<AppDispatch>(); // Typage correct de dispatch

    // Fonction pour changer le statut de la mission
    const handleStatusChange = (missionUuid: string, newStatus: string) => {
        if (projectUuid) { // S'assurer que projectUuid n'est pas undefined
            dispatch(updateMissionStatusThunk({ projectUuid, missionUuid, status: newStatus }));
        }
    };

    // Fonction pour déterminer la couleur du texte du statut
    const getStatusTextColor = (status: string) => {
        switch (status) {
            case 'draft':
                return 'text-gray-400'; // Texte gris pour les missions en Draft
            case 'in_progress':
                return 'text-blue-500'; // Texte bleu pour les missions en cours
            case 'completed':
                return 'text-green-500'; // Texte vert pour les missions terminées
            case 'cancelled':
                return 'text-red-500'; // Texte rouge pour les missions annulées
            default:
                return 'text-gray-200'; // Texte par défaut en gris clair
        }
    };

    // Fonction pour déterminer la couleur du point en fonction du statut
    const getStatusColor = (status: string) => {
        switch (status) {
            case 'draft':
                return 'bg-gray-400'; // Point gris pour les missions en Draft
            case 'in_progress':
                return 'bg-blue-500'; // Point bleu pour les missions en cours
            case 'completed':
                return 'bg-green-500'; // Point vert pour les missions terminées
            case 'cancelled':
                return 'bg-red-500'; // Point rouge pour les missions annulées
            default:
                return 'bg-gray-200'; // Point par défaut en gris clair
        }
    };

    // Fonction pour déterminer l'icône à afficher en fonction du statut
    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'draft':
                return;
            case 'in_progress':
                return <FiRotateCw className="text-black dark:text-white" size="14" />; // Icône en cours pour le statut "in_progress"
            case 'completed':
                return <FiCheck className="text-black dark:text-white" size="14" />; // Icône de validation pour le statut "completed"
            default:
                return <FiClock className="text-black dark:text-white" size="14" />; // Icône par défaut
        }
    };

    return (
        <ol className="relative mt-4 ml-3 border-l border-gray-200 dark:border-gray-700">
            {[...missions].reverse().map((mission) => (
                <li key={mission.uuid} className="mb-10 ml-6">
                    {/* Point de la timeline avec couleur dynamique en fonction du statut */}
                    <span
                        className={`absolute flex items-center justify-center w-6 h-6 border-2 border-theme bg-white dark:bg-darkSecondaryBgColor rounded-full -left-3 ring-8 ring-white dark:ring-darkSecondaryBgColor`}>
                        {/* Icône dynamique en fonction du statut */}
                        {getStatusIcon(mission.status)}
                    </span>
                    {/* Titre et statut sur la même ligne */}
                    <div className="ml-4 flex items-center justify-between">
                        <div>
                            <time
                                className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                Date de fin : {new Date(mission.exceptedEndDate).toLocaleDateString('fr-FR')}
                            </time>
                            <div className="flex items-center mb-2">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    {mission.title}
                                </h3>
                            </div>
                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                {mission.description}
                            </p>
                            <p className="text-xs text-gray-500 font-bold">Montant : {formatPrice(mission.amount)} €</p>
                        </div>
                        <div>
                            {/* Affichage des boutons en fonction du statut */}
                            {mission.status === 'draft' && (
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleStatusChange(mission.uuid, 'in_progress')}
                                >
                                    Commencer
                                </button>
                            )}
                            {mission.status === 'in_progress' && (
                                <button
                                    className="bg-green-500 text-white px-4 py-2 rounded"
                                    onClick={() => handleStatusChange(mission.uuid, 'completed')}
                                >
                                    Terminer
                                </button>
                            )}
                        </div>
                    </div>
                    <iframe className="test"
                            src="https://embed.figma.com/proto/c0WTOkuVXLP2mfRHE3tykG/Caen-Couverture?page-id=598%3A2301&node-id=670-3286&node-type=frame&viewport=4328%2C1578%2C0.24&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=670%3A8855&embed-host=share"
                            allowFullScreen>
                    </iframe>
                </li>
            ))}
        </ol>
    );
};

const ProjectDetail: React.FC<ProjectDetailProps> = ({onEditProject}) => {
    const {uuid} = useParams<{ uuid: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState<'timeline' | 'documents'>('timeline');

    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    const toggleDetails = () => {
        setIsDetailsVisible(!isDetailsVisible);
    };

    const project = useSelector((state: RootState) =>
        uuid ? selectProjectById(state, uuid) : undefined
    );

    const customerUuid = typeof project?.customer === 'string'
        ? project.customer
        : (project?.customer && 'uuid' in project.customer)
            ? (project.customer as Customer).uuid
            : undefined;

    const customer = useSelector((state: RootState) =>
        customerUuid ? selectCustomerById(state, customerUuid) : undefined
    );

    const projectLoading = useSelector(selectProjectsLoading);
    const missions = useSelector((state: RootState) =>
        uuid ? selectMissionsByProjectId(state, uuid) : []
    );

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (uuid && !project) {
            dispatch(fetchProjectById(uuid));
        }
    }, [dispatch, uuid, project]);

    useEffect(() => {
        if (customerUuid && !customer) {
            dispatch(fetchCustomerById(customerUuid));
        }
    }, [dispatch, customerUuid, customer]);

    useEffect(() => {
        if (uuid) {
            dispatch(fetchMissionsByProjectId(uuid));
        }
    }, [dispatch, uuid]);

    const handleDeleteClick = () => {
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (uuid) {
            setDeleting(true);
            await dispatch(deleteProject(uuid));
            setDeleting(false);
            navigate('/projects');
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleEdit = () => {
        if (project) {
            onEditProject(project);
        }
    };

    const [openMissions, setOpenMissions] = useState<string[]>([]); // state to track which missions are open

    const toggleMission = (missionUuid: string) => {
        setOpenMissions(prevOpenMissions =>
            prevOpenMissions.includes(missionUuid)
                ? prevOpenMissions.filter(id => id !== missionUuid)
                : [...prevOpenMissions, missionUuid]
        );
    };

    // Trouver la date la plus éloignée parmi les missions
    const mostDistantDate = missions
        .map(mission => new Date(mission.exceptedEndDate))
        .reduce((latest, date) => (date > latest ? date : latest), new Date(0));

    // Formater la date la plus éloignée (par exemple, au format '20 août 2024')
    const formatDateTest = (date: Date) => {
        return date.toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    const totalMissions = missions.length;
    const completedMissions = missions.filter(mission => mission.status === 'completed').length;
    const completionPercentage = totalMissions > 0 ? (completedMissions / totalMissions) * 100 : 0;

    if (projectLoading || !project) {
        return (
            <div className="p-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    return (
        <div className="flex">
            <div className="w-2/3">
                <div>
                    <div className="w-24 h-24 bg-[#E5DDF9]"></div>
                    <h1 className="text-2xl font-bold mt-4 mb-2 text-theme">{project.title}</h1>
                    <div className="flex space-x-2 mt-8">
                        <span className="bg-[#D4CFFF] rounded-full px-4 py-1 text-xs font-medium">
                            {project.status.label}
                        </span>
                        <span className="bg-[#E8DCEE] rounded-full px-4 py-1 text-xs font-medium">
                            UI / UX
                        </span>
                        <span className="border-2 border-theme rounded-full px-4 py-1 text-xs font-medium">
                            {formatDateTest(mostDistantDate)}
                        </span>
                    </div>
                    <div
                        className="bg-gradient-to-r from-[#EDDBDE] via-[#E8DCEE] to-[#E5DDF9] flex justify-between items-center mt-8 p-4 rounded-lg">
                        <div className="flex items-center">
                            <div className="bg-black w-8 h-8 flex items-center justify-center rounded-lg">
                                <span className="text-white text-base">€</span>
                            </div>
                            <p className="ml-4 text-sm">Prix total du projet TTC</p>
                        </div>
                        <div className="flex items-center">
                            <p className="text-2xl font-bold">{formatPrice(project.amount)} €</p>
                            <FiInfo className="ml-4"/>
                        </div>
                    </div>
                    <div className="mt-8">
                        <p className="text-theme text-sm text-theme font-medium">Description</p>
                        <p className="text-sm mt-2 text-theme">{project.description}</p>
                    </div>
                </div>

                {/* Documents */}
                <div className="mt-8">
                    <p className="text-theme text-sm text-theme font-medium">Pièces jointes</p>
                    <DragScrollContainer className="flex space-x-4 mt-2">
                        {project.documents && project.documents.length > 0 ? (
                            project.documents.map((document: any) => {
                                return (
                                    <div
                                        key={document.uuid}
                                        className="flex bg-theme-light border-theme p-4 rounded border-2 items-center cursor-pointer"
                                    >
                                        <div className="text-xl mr-4 bg-gray-100 p-4 rounded">
                                            {document.mimeType === 'application/pdf' ? <FiFile/> : <FiImage/>}
                                        </div>
                                        <div className="w-full">
                                            <p className="text-base font-medium w-48 truncate text-theme">
                                                {document.originalName}
                                            </p>
                                            <span className="text-sm text-theme">
                                                {formatDate(document.createdAt)}{' '}
                                            </span>
                                            <span className="text-sm text-theme">{formatFileSize(document.size)}</span>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-theme">Aucun document disponible pour ce projet.</p>
                        )}
                    </DragScrollContainer>
                </div>

                {/* Onglets pour Timeline et Documents */}
                <div className="mt-8">
                    <div className="flex space-x-4">
                        {/* Bouton Timeline */}
                        <button
                            onClick={() => setActiveTab('timeline')}
                            className={`whitespace-nowrap py-2 px-4 rounded-md text-sm font-medium ${
                                activeTab === 'timeline' ? 'bg-gray-100 text-black' : 'text-gray-500 hover:bg-gray-100'
                            }`}
                        >
                            Timeline
                        </button>

                        {/* Bouton Document */}
                        <button
                            onClick={() => setActiveTab('documents')}
                            className={`whitespace-nowrap py-2 px-4 rounded-md text-sm font-medium ${
                                activeTab === 'documents' ? 'bg-gray-100 text-black' : 'text-gray-500 hover:bg-gray-100'
                            }`}
                        >
                            Documents
                        </button>
                    </div>

                    {/* Contenu conditionnel : Timeline ou Documents */}
                    <div className="mt-6">
                        {activeTab === 'timeline' ? (
                            <>
                                <h2 className="text-xl font-semibold text-theme">Missions Timeline</h2>
                                {missions.length > 0 && uuid ? (
                                    <Timeline missions={missions} projectUuid={uuid} />
                                ) : (
                                    <p className="text-theme">Aucune mission associée à ce projet.</p>
                                )}
                            </>
                        ) : (
                            <>
                                {project.documents && project.documents.length > 0 ? (
                                    project.documents.map((document: any) => {
                                        return (
                                            <div
                                                key={document.uuid}
                                                className="flex bg-theme-light border-theme p-4 mb-4 rounded border-2 items-center cursor-pointer"
                                            >
                                                <div className="text-xl mr-4 bg-gray-100 p-4 rounded">
                                                    {document.mimeType === 'application/pdf' ? <FiFile/> : <FiImage/>}
                                                </div>
                                                <div className="w-full">
                                                    <p className="text-base font-medium w-48 truncate text-theme">
                                                        {document.originalName}
                                                    </p>
                                                    <span className="text-sm text-theme">
                                                        {formatDate(document.createdAt)}{' '}
                                                    </span>
                                                    <span className="text-sm text-theme">{formatFileSize(document.size)}</span>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p className="text-theme">Aucun document disponible pour ce projet.</p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Right-side Project Information */}
            <div className="w-1/3 ml-12" style={{position: 'sticky', top: '0', height: 'fit-content'}}>
                <div className="p-4 bg-red-500 flex items-center justify-between rounded">
                    <p className="text-white">Vous avez un devis a signer</p>
                    <button>Signer</button>
                </div>
                <div className="mt-8">
                    <div className="flex justify-between items-center">
                        <p className="text-lg font-semibold text-theme">Status du projet</p>
                        <span>{completionPercentage}%</span>
                    </div>
                    <div className="w-full bg-gray-300 h-2 rounded-full mt-2">
                        <div className="bg-blue-500 h-2 rounded-full" style={{width: `${completionPercentage}%`}}></div>
                    </div>
                </div>
                {customer ? (
                    <div className="mt-8 bg-theme-light border-theme border-2 border-secondaryBgColor rounded">
                        <div className="flex items-center border-b-2 border-theme p-4">
                            <div className="bg-[#D27A47] w-9 h-9 flex items-center justify-center rounded-full mr-4">
                                <span className="text-white text-lg">{customer.firstName.charAt(0).toUpperCase()}</span>
                            </div>
                            <div>
                                <p className="font-medium text-base text-theme">
                                    {customer.firstName} {customer.lastName}
                                </p>
                                <p className="text-xs text-theme">{customer.companyName}</p>
                            </div>
                        </div>

                        {/* Bouton "voir plus" */}
                        <div className="flex justify-between items-center p-4 cursor-pointer" onClick={toggleDetails}>
                            <p>Voir plus</p>
                            <div>{isDetailsVisible ? <FiChevronUp/> : <FiChevronDown/>}</div>
                        </div>

                        {/* Détails conditionnels */}
                        {isDetailsVisible && (
                            <div className="p-4">
                                <div className="flex items-center">
                                    <div
                                        className="bg-transparent w-9 h-8 flex items-center justify-center rounded-full mr-4">
                                        <FiPhone className="text-gray-600"/>
                                    </div>
                                    <p className="text-ms text-theme">{customer.phone}</p>
                                </div>
                                <div className="flex items-center">
                                    <div
                                        className="bg-transparent w-9 h-8 flex items-center justify-center rounded-full mr-4">
                                        <FiMail className="text-gray-600"/>
                                    </div>
                                    <p className="text-ms text-theme">{customer.email}</p>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    'Chargement...'
                )}
            </div>
        </div>
    );
};

export default ProjectDetail;