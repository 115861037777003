import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerById, selectCustomerById, deleteCustomer, selectCustomersLoading } from './customerSlice';
import { fetchProjects, selectProjects } from '../project/projectSlice'; // Importer les projets
import Skeleton from '../../components/Skeleton';
import Modal from '../../components/Modal';
import { AppDispatch, RootState } from '../../store/store';

interface CustomerDetailProps {
    onEditCustomer: (customer: any) => void;
}

interface CustomerObject {
    uuid: string;
}

function isCustomerObject(customer: any): customer is CustomerObject {
    return customer && typeof customer === 'object' && 'uuid' in customer;
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({ onEditCustomer }) => {
    const { uuid } = useParams<{ uuid: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const customer = useSelector((state: RootState) =>
        uuid ? selectCustomerById(state, uuid) : undefined
    );
    const customerLoading = useSelector(selectCustomersLoading);

    const projects = useSelector(selectProjects); // Sélectionner tous les projets du store

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        if (uuid && !customer) {
            dispatch(fetchCustomerById(uuid));
        }
        if (projects.length === 0) {
            dispatch(fetchProjects()); // Récupérer les projets si ce n'est pas déjà fait
        }
    }, [dispatch, uuid, customer, projects.length]);

    const handleDeleteClick = () => {
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (uuid) {
            setDeleting(true);
            await dispatch(deleteCustomer(uuid));
            setDeleting(false);
            navigate('/customers'); // Redirige vers la liste des clients après la suppression
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
    };

    const handleEdit = () => {
        if (customer) {
            onEditCustomer(customer); // Ouvrir le modal pour éditer le client
        }
    };

    if (customerLoading || !customer) {
        return (
            <div className="p-4">
                <Skeleton width="50%" height="30px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
                <Skeleton width="100%" height="20px" />
            </div>
        );
    }

    // Filtrer les projets liés au client actuel en vérifiant d'abord si project.customer est un objet
    const customerProjects = projects.filter(project =>
        isCustomerObject(project.customer) && project.customer.uuid === customer.uuid
    );

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4">Détails du client</h2>
            <p><strong>Nom de l'entreprise:</strong> {customer.companyName}</p>
            <p><strong>Prénom:</strong> {customer.firstName}</p>
            <p><strong>Nom:</strong> {customer.lastName}</p>
            <p><strong>Email:</strong> {customer.email}</p>
            <p><strong>Téléphone:</strong> {customer.phone}</p>

            <div className="mt-4">
                <h3 className="text-lg font-semibold">Projets associés:</h3>
                {customerProjects.length > 0 ? (
                    <ul className="list-disc pl-5 mt-2">
                        {customerProjects.map(project => (
                            <li key={project.uuid}>
                                <Link to={`/projects/${project.uuid}`} className="text-blue-500 underline">
                                    {project.title} - {project.description}
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Aucun projet associé.</p>
                )}
            </div>

            <div className="mt-4 flex space-x-2">
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={handleEdit}
                >
                    Modifier
                </button>
                <button
                    className="px-4 py-2 bg-red-500 text-white rounded"
                    onClick={handleDeleteClick}
                >
                    Supprimer
                </button>
            </div>

            {/* Modal de confirmation de suppression */}
            <Modal show={showConfirmModal} onClose={cancelDelete} title="Confirmer la suppression">
                <p>Êtes-vous sûr de vouloir supprimer le client suivant ?</p>
                <p className="font-bold">{customer.firstName} {customer.lastName}</p>
                <div className="flex justify-end space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-500 text-white rounded" onClick={cancelDelete} disabled={deleting}>
                        Annuler
                    </button>
                    <button className="px-4 py-2 bg-red-500 text-white rounded flex items-center" onClick={confirmDelete} disabled={deleting}>
                        {deleting ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : 'Supprimer'}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default CustomerDetail;